import { GET_KM_PRICES, DELETE_KM_PRICE, ADD_KM_PRICE, 
    FETCH_KM_PRICE, EDIT_KM_PRICE, 
    CLEAR_ALL } from "constants/actions";
const initialState = {
    kilometerprice: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_KM_PRICES:
            return {
                ...state,
                kilometerprice: action.payload
            };
        case FETCH_KM_PRICE:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_KM_PRICE:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_KM_PRICE:
            return {
                ...state,
                kilometerprice: state.kilometerprice.filter(km => km.id !== action.payload)
            };
        case ADD_KM_PRICE:
            return {
                ...state,
                kilometerprice: [...state.kilometerprice, action.payload]
            };
        case CLEAR_ALL:
            return {
                ...state,
                kilometerprice: []
            };
        default:
            return state;
    }
}

