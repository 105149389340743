import React from "react";

// reactstrap components
import { Container, Button, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import MyMapComponent from "utilities/GoogleMap";
import config from "_config";
import "./footer.css";
const Footer = () => {
  return (
    <>
      {/* <div className="separator" /> */}
      {/* <MyMapComponent isMarkerShown /> */}
      <>
        <hr />
        <footer className="footer pt-5 footer-big">
          <Container>
            <Row>
              <Col
                className="text-center ml-auto mr-auto"
                md="4"
                sm="4"
                xs="12"
              >
                <Link to="/">
                  <img
                    src={require("assets/images/logo/companyLogo.png")}
                    alt={config.company}
                    height="60px"
                  />
                </Link>
                <div className="social-area">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/brydenauto/">
                    <Button
                      className="btn-just-icon btn-round mr-1"
                      color="facebook"
                    >
                      <i className="fa fa-facebook" />
                    </Button>
                  </a>

                  <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/BrydenFinancing/">
                    <Button
                      className="btn-just-icon btn-round mr-1"
                      color="twitter"
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                  </a>

                  <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/bryden_financing/">
                    <Button className="btn-just-icon btn-round" color="google">
                      <i className="fa fa-instagram" />
                    </Button>
                  </a>

                </div>
              </Col>
              <Col className="ml-auto mr-auto" md="8" sm="8" xs="12">
                <Row>
                  <Col md="3" sm="3" xs="6">
                    <div className="links">
                      <ul className="uppercase-links stacked-links">
                        <li>
                          <Link to="/inventory">Inventory</Link>
                        </li>
                        <li>
                          <Link to="/sell">Cash for Car</Link>
                        </li>
                        <li>
                          <Link to="/credit-application">Finance</Link>
                        </li>

                        <li>
                          <Link to="/auto-loan-calculator">Calculator</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col md="3" sm="3" xs="6">
                    <div className="links">
                      <ul className="uppercase-links stacked-links">
                        <li>
                          <Link to="/about-us">About</Link>
                        </li>
                        <li>
                          <Link to="/customer-feeback">Feedback</Link>
                        </li>
                        <li>
                          <Link to="#">Warranty</Link>
                        </li>
                        <li>
                          <Link to="/team">Team</Link>
                        </li>
                        {/* <li>
                      <Link to="/team">We're Hiring</Link>
                      </li> */}

                        {/* <li>
                      <Link to="/digital-contract">Digital Contract Demo</Link>
                        
                      </li>
                      <li>
                      <Link to="/fb-inventory-feed.xml" 
                      target="_blank" 
                      rel="noopener noreferrer">Facebook Feed</Link>  
                      </li> */}
                      </ul>
                    </div>
                  </Col>

                  <Col md="4" sm="12" xs="12">
                    <div className="links">
                      <ul className="stacked-links">
                        <li>
                          <h6>
                            {config.address}
                            <br />
                            {config.city}
                            <br />
                            {config.province} {config.pincode}
                          </h6>
                        </li>

                        <li>
                          <h6>Phone: {config.phone}</h6>
                        </li>
                        <li>
                          <h5 className="toll-free">Toll Free {config.tollfree}</h5>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <hr />
                <div className="copyright">
                  <div className="pull-left">
                    © {new Date().getFullYear()} {config.company}
                  </div>
                  <div className="links pull-right">
                    <ul>
                      <li className="mr-1">
                        <a
                          href="https://www.preatoco.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Powered by PreAutoCo.com
                        </a>
                      </li>
                      
                      |{" "}
                      <li className="mr-1">
                        <Link to="/terms-and-conditions">
                          Terms and Conditions
                        </Link>
                      </li>
                      |{" "}
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    </>
  );
};

export default Footer;
