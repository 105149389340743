import { GET_WEBCONTENTS, FETCH_WEBCONTENT, DELETE_WEBCONTENT, 
    ADD_WEBCONTENT, EDIT_WEBCONTENT, 
    CLEAR_WEBCONTENT, CLEAR_ALL } from "constants/actions";

const initialState = {
    webcontents: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_WEBCONTENTS:
            return {
                ...state,
                webcontents: action.payload
            };
        case FETCH_WEBCONTENT:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_WEBCONTENT:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_WEBCONTENT:
            return {
                ...state,
                webcontents: state.webcontents.filter(webcontent => webcontent.id !== action.payload)
            };
        case ADD_WEBCONTENT:
            console.log(...state.webcontents)
            console.log(action.payload)
            return {
                ...state,
                webcontents: [...state.webcontents, action.payload]
            };
        case CLEAR_WEBCONTENT:
            return {
                ...state,
                webcontents: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                webcontents: []
            };
        default:
            return state;
    }
}

