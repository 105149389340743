import { GET_CREDITAPPLICATIONPERSONALDETAILS, FETCH_CREDITAPPLICATIONPERSONALDETAIL, DELETE_CREDITAPPLICATIONPERSONALDETAIL, 
    ADD_CREDITAPPLICATIONPERSONALDETAIL, EDIT_CREDITAPPLICATIONPERSONALDETAIL,
    CLEAR_CREDITAPPLICATIONPERSONALDETAIL, CLEAR_ALL
} from "constants/actions";

const initialState = {
    creditapplicationpersonaldetails: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CREDITAPPLICATIONPERSONALDETAILS:
            return {
                ...state,
                creditapplicationpersonaldetails: action.payload
            };
        case FETCH_CREDITAPPLICATIONPERSONALDETAIL:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_CREDITAPPLICATIONPERSONALDETAIL:
            return { ...state, [action.payload.id]: action.payload };


        case DELETE_CREDITAPPLICATIONPERSONALDETAIL:
            return {
                ...state,
                creditapplicationpersonaldetails: state.creditapplicationpersonaldetails.filter(creditapplicationpersonaldetail => creditapplicationpersonaldetail.id !== action.payload)
            };

        case ADD_CREDITAPPLICATIONPERSONALDETAIL:
            return {
                ...state,
                creditapplicationpersonaldetails: [...state.creditapplicationpersonaldetails, action.payload]
            };
        case CLEAR_CREDITAPPLICATIONPERSONALDETAIL:
            return {
                ...state,
                creditapplicationpersonaldetails: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                creditapplicationpersonaldetails: []
            };
        default:
            return state;
    }
}

