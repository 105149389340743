import { GET_CREDITAPPLICATIONEMPLOYMENTS, FETCH_CREDITAPPLICATIONEMPLOYMENT, 
    DELETE_CREDITAPPLICATIONEMPLOYMENT, ADD_CREDITAPPLICATIONEMPLOYMENT, 
    EDIT_CREDITAPPLICATIONEMPLOYMENT, 
    CLEAR_CREDITAPPLICATIONEMPLOYMENT, CLEAR_ALL 
} from "constants/actions";

const initialState = {
    creditapplicationemployments: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CREDITAPPLICATIONEMPLOYMENTS:
            return {
                ...state,
                creditapplicationemployments: action.payload
            };
        case FETCH_CREDITAPPLICATIONEMPLOYMENT:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_CREDITAPPLICATIONEMPLOYMENT:
            return { ...state, [action.payload.id]: action.payload };


        case DELETE_CREDITAPPLICATIONEMPLOYMENT:
            return {
                ...state,
                creditapplicationemployments: state.creditapplicationemployments.filter(dealer => dealer.id !== action.payload)
            };

        case ADD_CREDITAPPLICATIONEMPLOYMENT:
            return {
                ...state,
                creditapplicationemployments: [...state.creditapplicationemployments, action.payload]
            };
        case CLEAR_CREDITAPPLICATIONEMPLOYMENT:
            return {
                ...state,
                creditapplicationemployments: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                creditapplicationemployments: []
            };
        default:
            return state;
    }
}

