import { put, all, call, takeLatest } from 'redux-saga/effects'
import * as api from 'constants/api';

import {  ADD_LEAD, CREATE_LEAD,
    UPDATE_LEADS, SG_UPDATE_LEADS ,
     ALL_ERRORS } from "constants/actions";

  function* submitWebLeads(action) {
    try {
      yield put({ type: ADD_LEAD, payload: action.payload });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }


  export function* WebleadSubmit() {
    yield takeLatest(CREATE_LEAD, submitWebLeads);
  }

  function* UpdateWebLeads(action) {
    try {
      yield put({ type: UPDATE_LEADS, payload: action.payload });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }


  export function* WebleadUpdate() {
    yield takeLatest(SG_UPDATE_LEADS, UpdateWebLeads);
  }

  export default function* index() {
    yield all([WebleadSubmit(), WebleadUpdate()]);
  }