import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from 'react-router-dom'
// import InputRange from "react-input-range";
import {
  Card,
  CardText,
  CardBody,
  CardFooter,
  Row,
  Col,
  Input,
} from "reactstrap";


import Slider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import "./style.css";
import CustomSlider from "./Slider";

import Calculate from "Calculator/Calculate"

import config from "_config";
export function Calculator() {
  const search = window.location.search;
  const params = new URLSearchParams(search);

//   if (localStorage.getItem("interestValue")){
//     interestValue = localStorage.getItem("interestValue")
// }
// if (localStorage.getItem("amountValue")){
//     amountValue = localStorage.getItem("amountValue")
// }
// if (localStorage.getItem("yearsValue")){
//     yearsValue = localStorage.getItem("yearsValue")
// }
// if (localStorage.getItem("downPayment")){
//     downPayment = localStorage.getItem("downPayment")
// }
// if (localStorage.getItem("tradeinValue")){
//     tradeinValue = localStorage.getItem("tradeinValue")
// }
  const [amountValue, setAmountValue] = useState(
    localStorage.getItem("amountValue")? 
    localStorage.getItem("amountValue") : 23390);

  const [financeValue, setfinanceValue] = useState(
    localStorage.getItem("amountValue")? 
    localStorage.getItem("amountValue") : 23390);

  const [yearsValue, setYearsValue] = useState(
    localStorage.getItem("yearsValue")? 
    localStorage.getItem("yearsValue") :5);
  const [interestValue, setInterestValue] = useState(
    localStorage.getItem("interestValue")? 
    localStorage.getItem("interestValue") : 499);

  const [tradeinValue, settradeinValue] = useState(
    localStorage.getItem("tradeinValue")? 
    localStorage.getItem("tradeinValue") : 5000);

  const [downPayment, setdownPayment] = useState(
    localStorage.getItem("downPayment")? 
    localStorage.getItem("downPayment") : 5000);

  const _Interest = params.get("interest");
  
  useEffect(() => {
    if (_Interest) {
      setInterestValue(_Interest)
    }
  },[_Interest])

  // const _Amount = params.get("amount");
  // useEffect(() => {
  //   if (_Amount > 4999) {
  //     // console.log("I was here")
  //     // setAmountValue(_Amount)
  //   }
  // },[_Amount])

  const _Years = params.get("years");
  // useEffect(() => {
  //   if (_Years) {
  //     setYearsValue(_Years)
  //   }
  // },[_Years])

  const _TradeIn = params.get("trade");
  useEffect(() => {
    if (_TradeIn) {
      settradeinValue(_TradeIn)
    }
  },[_TradeIn])

  const _Down = params.get("down");
  useEffect(() => {
    if (_Down) {
      setdownPayment(_Down)
    }
  },[_Down])

useEffect(() => {
  localStorage.setItem("interestValue", interestValue);
  localStorage.setItem("amountValue", amountValue);
  // localStorage.setItem("yearsValue", yearsValue);
  localStorage.setItem("tradeinValue", tradeinValue);
  localStorage.setItem("downPayment", downPayment);
},[interestValue, amountValue, tradeinValue, downPayment])

  const handleDownChange = async (value) => {
    setdownPayment(value);
    // handleFinanceValue()
  };

  const handleTradeChange = async (value) => {
    // console.log("trade",value)
    settradeinValue(value);
    
    // 
    // handleFinanceValue()
  };
// const newURL = new URL(window.location.href);
const location = useLocation()
  const history = useHistory()
const handleAmountChange = async (value) => {
    setAmountValue(value);
    // console.log('handleAmountChange', value)
    // handleFinanceValue()
    // newURL.search = ``
    // window.history.pushState({ path: newURL.href }, "", newURL.href);

    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has('amount')) {
     
      queryParams.delete('amount')
      history.replace({
        search: queryParams.toString(),
      })
    }
 };

  const onDownChange = (e) => {
    setdownPayment(+e.target.value);
    // handleFinanceValue()
  };

  const onYearChange = (e) => {
    setYearsValue(+e.target.value);
    // handleFinanceValue()
  };

  const onTradeChange = (e) => {
    settradeinValue(+e.target.value);
    // handleFinanceValue()
  };
  const onAmountTextChange = (e) => {
    // console.log(e.target.value)
    setAmountValue([+e.target.value]);
    // Slider(e.target.value)
    // handleFinanceValue()
  };

  const handleYearChange = (value) => {
    setYearsValue(value);
  };

  const handleInterestChange = (value) => {
    setInterestValue(value);
  };


// console.log(amountValue)
    
  // let { amount, years, interest } = 0;
  // let amount = Number(amountValue[0]);
  let years = yearsValue;
  let interest = interestValue / 100;

  // console.log('amount',amountValue[0])
  // console.log('down', downPayment[0])
  // console.log("trade",tradeinValue[0])
  // console.log("financeValue", financeValue)

// console.log(Number(amountValue[0])  -(Number(downPayment[0]) + Number(tradeinValue[0])))
  React.useEffect(() =>{
    setfinanceValue(Number(amountValue[0])  -(Number(downPayment[0]) + Number(tradeinValue[0])))
  },[amountValue, downPayment, tradeinValue])
  return (
    <div>
      <Helmet>
        <title>
        Credit Experts Bryden Financing &amp; Auto Sales | Everyone's Approved
        </title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={`Credit Experts and Used cars in 
        ${config.city} get more deals by ${config.company}| Everyone's Approved` } />
      </Helmet>
      <Row>
        <Col className="text-left" md="12">
          <Card className="card-raised page-carousel">
            <CardBody>
              {" "}
              <div className="block-div">
                <p className="lead">Vehicle List Price</p>

                <Row>
                  <Col xs="12" sm="12" md="8" className="mt-3">
                    <CustomSlider
                      value={amountValue}
                      onChange={handleAmountChange}
                      min={0}
                      max={90000}
                      step={1000}
                      tooltips={"tooltips"}
                    />
                  </Col>
                  <Col xs="12" sm="12" md="4" className="space-on-small">
                   
                    <Input
                      type="number"
                      className="text-right"
                      
                      value={amountValue}
                      onChange={onAmountTextChange}
                    />
                  </Col>
                </Row>
              </div>
              <div className="block-div">
                <p className="lead">(minus) Trade Value</p>

                <Row>
                  <Col xs="12" sm="12" md="8" className="mt-3">
                    <CustomSlider
                      value={tradeinValue}
                      onChange={handleTradeChange}
                      min={0}
                      max={10000}
                      step={250}
                      tooltips={"tooltips"}
                    />
                  </Col>
                  <Col xs="12" sm="12" md="4" className="space-on-small">
                   
                    <Input
                      type="number"
                      className="text-right"
                      value={tradeinValue}
                      disabled
                      onChange={onTradeChange}
                      tooltips={"tooltips"}
                    />
                  </Col>
                </Row>
              </div>
              <div className="block-div">
                <p className="lead">(minus) Down Payment</p>

                <Row>
                  <Col xs="12" md="8" className="mt-3">
                    <CustomSlider
                      value={downPayment}
                      onChange={handleDownChange}
                      min={0}
                      max={20000}
                      step={100}
                      tooltips={"tooltips"}
                    />
                  </Col>
                  <Col xs="12" sm="12" md="4" className="space-on-small">
                    <Input
                      type="number"
                      className="text-right"
                      disabled
                      value={downPayment}
                      onChange={onDownChange}
                    />
                  </Col>
                </Row>
              </div>
              <div className="block-div">
                <p className="lead">
                  Over {Math.round(yearsValue)} year{years > 1 && "s"}
                </p>

                <Row>
                  <Col xs="12" md="8" className="mt-3">
                    <CustomSlider
                      value={Math.round(yearsValue)}
                      onChange={handleYearChange}
                      min={1}
                      max={8}
                      step={1}
                    />
                  </Col>
                  <Col xs="12" md="4">
                  <Input
                      type="number"
                      className="text-right"
                      disabled
                      value={yearsValue}
                      onChange={onYearChange}
                    />
                  </Col>
                </Row>
              </div>
              <div className="block-div">
                <p className="lead"> {interest.toFixed(2)}% Interest</p>

                <Row>
                  <Col xs="12" md="8" className="mt-3">
                    <CustomSlider
                      value={Math.round(interestValue).toFixed(2)}
                      onChange={handleInterestChange}
                      min={199}
                      max={5000}
                    />
                  </Col>
                  <Col xs="12" md="4"></Col>
                </Row>
              </div>
              <div className="block-div">
                <Row>
                  <Col xs="12" md="7">
                    <h3 className="lead-total">Total Finance</h3>
                  </Col>
                  <Col
                    xs="12"
                    md="5"
                    className="total-value-highlight"
                  >
                    <h3 className="lead-total-number"> 
                       {new Intl.NumberFormat("en-CA", {
                      style: "currency",
                      currency: "CAD",
                    }).format(financeValue)}
                    </h3>
                   
                  </Col>
                </Row>
              </div>
            </CardBody>
            <hr />
            
            <Calculate 
            interestValue={interestValue}
             amountValue={amountValue} 
             yearsValue={yearsValue}
              downPayment={downPayment}
               tradeinValue={tradeinValue}
 />
          </Card>
        </Col>
      </Row>

      <small>Indicative values shown</small>
    </div>
  );
}

export function LongCalculator() {
  const search = window.location.search;
  const params = new URLSearchParams(search);


  const [amountValue, setAmountValue] = useState(23390);
  const [financeValue, setfinanceValue] = useState(23390);

  const [yearsValue, setYearsValue] = useState(5);
  const [interestValue, setInterestValue] = useState(899);


  const _theApr = params.get("interest")? params.get("interest") : 499;
  if (_theApr) {
      localStorage.setItem("theApr", _theApr);
  }

 
  const [tradeinValue, settradeinValue] = useState(0);
  const [downPayment, setdownPayment] = useState(0);


  const SliderWithTooltip = createSliderWithTooltip(Slider);

  const radioforOutput2 = () => {
    let currAPR = interestValue / 100 / 100;
    // theApr = currAPR
    const decimalFormat = currAPR + 1;
    const totalOwed = decimalFormat * financeValue;
    const monthlyRepayment = totalOwed / (yearsValue * 12);
    const biWeeklyRepayment = totalOwed / (yearsValue * 26);
    const weeklyRepayment = totalOwed / (yearsValue * 52);

    return (
      <Row>
        <Col sm="4">
          <Card body>
            <CardText>Weekly</CardText>
            <CardText>
              <p className="value-highlight">${Math.round(weeklyRepayment)}</p>
            </CardText>
          </Card>
        </Col>
        <Col sm="4">
          <Card body>
            <CardText>Bi Weekly</CardText>
            <CardText>
              <p className="value-highlight">
                ${Math.round(biWeeklyRepayment)}
              </p>
            </CardText>
          </Card>
        </Col>
        <Col sm="4">
          <Card body>
            <CardText>Monthly</CardText>
            <CardText>
              <p className="value-highlight">${Math.round(monthlyRepayment)}</p>
            </CardText>
          </Card>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    setfinanceValue(amountValue - (downPayment + tradeinValue));
  }, [amountValue, tradeinValue, downPayment]);

  const handleDownChange = async (value) => {
    setdownPayment(value);
    // handleFinanceValue()
  };

  const handleTradeChange = async (value) => {
    // console.log("trade",value)
    settradeinValue(value);
    // console.log("trade",tradeinValue)
    // setfinanceValue(amountValue - (downPayment + value))
    // handleFinanceValue()
  };

  const handleAmountChange = async (value) => {
    setAmountValue(value);
    // handleFinanceValue()
  };

  const onDownChange = (e) => {
    setdownPayment(+e.target.value);
    // handleFinanceValue()
  };

  const onTradeChange = (e) => {
    settradeinValue(+e.target.value);
    // handleFinanceValue()
  };
  const onAmountTextChange = (e) => {
    
    setAmountValue(e.target.value);
    // handleFinanceValue()
  };

  const handleYearChange = (value) => {
    setYearsValue(value);
  };

  const handleInterestChange = (value) => {
    setInterestValue(value);
  };

  // let { amount, years, interest } = 0;
  // console.log(amountValue)

  let years = yearsValue;
  let interest = interestValue / 100;
  return (
    <div>
      <Row>
        <Col className="text-center" md="12">
          <Card className="card-contributions">
            <CardBody>
              <h5>
                {" "}
                <Row>
                  <Col xs="7">Amount $</Col>
                  <Col xs="5" className="text-right">
                    <Input
                      type="number"
                      className="text-right"
                      value={amountValue}
                      onChange={onAmountTextChange}
                    />
                  </Col>
                  <Col xs="12">
                    <SliderWithTooltip
                      value={amountValue}
                      onChange={handleAmountChange}
                      min={5000}
                      max={90000}
                      step={1000}
                      railStyle={{ backgroundColor: "gray", height: 2 }}
                      trackStyle={{ backgroundColor: "blue", height: 2 }}
                    />
                  </Col>
                </Row>
              </h5>

              <h5>
                {" "}
                <Row>
                  <Col xs="7">- Trade $</Col>
                  <Col xs="5" className="text-right">
                    <Input
                      type="number"
                      className="text-right"
                      value={tradeinValue}
                      onChange={onTradeChange}
                    />
                  </Col>
                  <Col xs="12">
                    <SliderWithTooltip
                      value={tradeinValue}
                      onChange={handleTradeChange}
                      min={1000}
                      max={10000}
                      step={250}
                      railStyle={{ backgroundColor: "gray", height: 2 }}
                      trackStyle={{ backgroundColor: "blue", height: 2 }}
                    />
                  </Col>
                </Row>
              </h5>

              <h5>
                {" "}
                <Row>
                  <Col xs="7">- Down $</Col>
                  <Col xs="5" className="text-right">
                    <Input
                      type="number"
                      className="text-right"
                      value={downPayment}
                      onChange={onDownChange}
                    />
                  </Col>
                  <Col xs="12">
                    <SliderWithTooltip
                      value={downPayment}
                      onChange={handleDownChange}
                      min={500}
                      max={5000}
                      step={100}
                      railStyle={{ backgroundColor: "gray", height: 2 }}
                      trackStyle={{ backgroundColor: "blue", height: 2 }}
                    />
                  </Col>
                </Row>
              </h5>

              <h5>
                <Row>
                  <Col xs="7">Total Finance $</Col>
                  <Col xs="5" className="text-right">
                    {financeValue}{" "}
                  </Col>
                </Row>
              </h5>

              <h5>
                Over {years} year{years > 1 && "s"}
                <SliderWithTooltip
                  value={yearsValue}
                  onChange={handleYearChange}
                  min={1}
                  max={8}
                  railStyle={{ backgroundColor: "gray", height: 2 }}
                  trackStyle={{ backgroundColor: "blue", height: 2 }}
                />
              </h5>
              <h5>
                {interest}% Interest
                <SliderWithTooltip
                  value={interestValue}
                  onChange={handleInterestChange}
                  min={199}
                  max={2500}
                  railStyle={{ backgroundColor: "gray", height: 2 }}
                  trackStyle={{ backgroundColor: "blue", height: 2 }}
                />
              </h5>
            </CardBody>

            {/* {radioforOutput2()} */}

            <small>Indicative values shown</small>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Calculator;
