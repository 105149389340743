import React from "react";
import ReactDOM from "react-dom";


// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import LogRocket from 'logrocket';
// LogRocket.init('tt25ss/preautoco');

ReactDOM.render(
  <App/>,
  document.getElementById("root")
);
serviceWorkerRegistration.register();