import { put, all,  takeLatest } from 'redux-saga/effects'
import { GET_ACTIVE_PAGE, SG_GET_ACTIVE_PAGE, 
    UPDATE_ACTIVE_PAGE, SG_UPDATE_ACTIVE_PAGE, 
    CLEAR_ALL } from "constants/actions";


function* loadGetActivePage() {
    // console.log(action.payload)
    yield put({ type: GET_ACTIVE_PAGE });
}
  
export function* getActivePageLoad() {
   yield takeLatest(SG_GET_ACTIVE_PAGE, loadGetActivePage);
}

function* loadUpdateActivePage(action) {
    // console.log(action.payload)
    yield put({ type: UPDATE_ACTIVE_PAGE, payload: action.payload, });
}
  
export function* updateActivePageLoad() {
   yield takeLatest(SG_UPDATE_ACTIVE_PAGE, loadUpdateActivePage);
}

export default function* index() {
    yield all([getActivePageLoad(), updateActivePageLoad()]);
  }

