import {
  GET_YEAR_MAKE_MODEL_TRIM,
  FETCH_YEAR_MAKE_MODEL_TRIM,
  SUBMIT_YEAR_MAKE_MODEL_TRIM,
  GET_YEAR_MAKE_MODEL_TRIM_PENDING,
  CLEAR_ALL,
} from "constants/actions";
const initialState = {
  yearmakemodels: [],
  selected: false,
  loading: false,
  submited: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_YEAR_MAKE_MODEL_TRIM_PENDING:
      return {
        ...state,
        loading: true,
      };
    case GET_YEAR_MAKE_MODEL_TRIM:
      return {
        ...state,
        yearmakemodels: action.payload,
        loading: false,
      };
    case FETCH_YEAR_MAKE_MODEL_TRIM:
      return { ...state,
        loading: false, 
        selected: action.payload
       };

     case SUBMIT_YEAR_MAKE_MODEL_TRIM:
        return {
            ...state,
            submited: [...state.submited, action.payload]
        };
 
    case CLEAR_ALL:
      return {
        ...state,
        yearmakemodels: [],
        selected: false,
        loading: false,
        submited: [],
      };
    default:
      return state;
  }
}
