import { put, all, takeLatest } from "redux-saga/effects";
import { 
CLEAR_YEARS,
RESET_YEARS,
SET_YEARS,
ADD_YEARS,
DELETE_YEARS,
REMOVE_YEARS,
CLEAR_MODELS,
RESET_MODELS,
SET_MODELS,
ADD_MODELS,
DELETE_MODELS,
REMOVE_MODELS,
CLEAR_MAKES,
RESET_MAKES,
SET_MAKES,
ADD_MAKES,
DELETE_MAKES,
REMOVE_MAKES,
CLEAR_COLORS,
RESET_COLORS,
SET_COLORS,
ADD_COLORS,
DELETE_COLORS,
REMOVE_COLORS,
CLEAR_BODYTYPE,
RESET_BODYTYPE,
SET_BODYTYPE,
ADD_BODYTYPE,
DELETE_BODYTYPE,
REMOVE_BODYTYPE,
CLEAR_KILOMETER,
RESET_KILOMETER,
SET_KILOMETERS,
ADD_KILOMETERS,
CLEAR_PRICE,
RESET_PRICE,
SET_PRICES,
CLEAR_SEARCH,
RESET_SEARCH,
SET_SEARCH,
ADD_SEARCH,
DELETE_SEARCH,
REMOVE_SEARCH,
RESET_ALL_PARAM,
SG_RESET_ALL_PARAM,
ADD_PRICES,
    CLEAR_ALL } from "constants/actions";
// reset year
function* resetYears(action) {
  yield put({ type: CLEAR_YEARS, payload: action.payload });
}

export function* resetYearLoad() {
  yield takeLatest(RESET_YEARS, resetYears);
}

// add year
function* updateYears(action) {
    yield put({ type: ADD_YEARS, payload: action.payload });
  }
  
  export function* updateYearLoad() {
    yield takeLatest(SET_YEARS, updateYears);
  }

// edit year
function* editYears(action) {
  yield put({ type: DELETE_YEARS, payload: action.payload });
}

export function* editYearLoad() {
  yield takeLatest(REMOVE_YEARS, editYears);
}

// reset search
function* resetSearch(action) {
  yield put({ type: CLEAR_SEARCH, payload: action.payload });
}

export function* resetSearchLoad() {
  yield takeLatest(RESET_SEARCH, resetSearch);
}

// add Search
function* updateSearch(action) {
    yield put({ type: ADD_SEARCH, payload: action.payload });
  }
  
  export function* updateSearchLoad() {
    yield takeLatest(SET_SEARCH, updateSearch);
  }

// edit Search
function* editSearch(action) {
  yield put({ type: DELETE_SEARCH, payload: action.payload });
}

export function* editSearchLoad() {
  yield takeLatest(REMOVE_SEARCH, editSearch);
}


// reset makes
function* resetMakes(action) {
  yield put({ type: CLEAR_MAKES, payload: action.payload });
}

export function* resetMakesLoad() {
  yield takeLatest(RESET_MAKES, resetMakes);
}

// add make
function* updateMakes(action) {
    yield put({ type: ADD_MAKES, payload: action.payload });
}
  
export function* updateMakesLoad() {
    yield takeLatest(SET_MAKES, updateMakes);
}

// edit make
function* editMakes(action) {
  yield put({ type: DELETE_MAKES, payload: action.payload });
}

export function* editMakesLoad() {
  yield takeLatest(REMOVE_MAKES, editMakes);
}

// reset model
function* resetModels(action) {
  yield put({ type: CLEAR_MODELS, payload: action.payload });
}

export function* resetModelsLoad() {
  yield takeLatest(RESET_MODELS, resetModels);
}

// add model
function* updateModels(action) {
    yield put({ type: ADD_MODELS, payload: action.payload });
  }
  
export function* updateModelsLoad() {
    yield takeLatest(SET_MODELS, updateModels);
}

// edit model
function* editModels(action) {
  yield put({ type: DELETE_MODELS, payload: action.payload });
}

export function* editModelsLoad() {
  yield takeLatest(REMOVE_MODELS, editModels);
}

// reset colors
function* resetColors(action) {
  yield put({ type: CLEAR_COLORS , payload: action.payload });
}

export function* resetColorsLoad() {
  yield takeLatest(RESET_COLORS, resetColors);
}

// add colors
function* updateColors(action) {
    yield put({ type: ADD_COLORS , payload: action.payload });
}
  
export function* updateColorsLoad() {
    yield takeLatest(SET_COLORS, updateColors);
}

// edit colors
function* editColors(action) {
  yield put({ type: DELETE_COLORS, payload: action.payload });
}

export function* editColorsLoad() {
  yield takeLatest(REMOVE_COLORS, editColors);
}

// reset bodytypes
function* resetBodyType(action) {
  yield put({ type: CLEAR_BODYTYPE, payload: action.payload });
}

export function* resetBodyTypeLoad() {
  yield takeLatest(RESET_BODYTYPE, resetBodyType);
}

// add body type
function* updateBodyType(action) {
  yield put({ type: ADD_BODYTYPE, payload: action.payload });
  }
  
export function* updateBodyTypeLoad() {
  yield takeLatest(SET_BODYTYPE, updateBodyType);
}

// edit body type
function* editBodyType(action) {
  // console.log(action.payload)
  yield put({ type: DELETE_BODYTYPE, payload: action.payload });
  }
  
export function* editBodyTypeLoad() {
  // console.log("I ws here")
  yield takeLatest(REMOVE_BODYTYPE, editBodyType);
}

// reset kilometeres
function* resetKilometers(action) {
    yield put({ type: CLEAR_KILOMETER, payload: action.payload });
  }
  
  export function* resetKilometerLoad() {
    yield takeLatest(RESET_KILOMETER, resetKilometers);
  }
  
  // add kilometeres
  function* updateKilometers(action) {
      yield put({ type: ADD_KILOMETERS, payload: action.payload });
    }
    
  export function* updateKilometersLoad() {
      yield takeLatest(SET_KILOMETERS, updateKilometers);
  }


  // reset price
function* resetPrices(action) {
    yield put({ type: CLEAR_PRICE, payload: action.payload });
  }
  
  export function* resetPricesLoad() {
    yield takeLatest(RESET_PRICE, resetPrices);
  }
  
  // add price
  function* updatePrices(action) {
      yield put({ type: ADD_PRICES, payload: action.payload });
    }
    
  export function* updatePricesLoad() {
      yield takeLatest(SET_PRICES, updatePrices);
  }

  function* ResetAllFilters() {
    yield put({ type: RESET_ALL_PARAM });
  }
  
export function* ResetAllFiltersLoad() {
    yield takeLatest(SG_RESET_ALL_PARAM, ResetAllFilters);
}

export default function* index() {
  yield all([
    resetYearLoad(),
    updateYearLoad(),
    editYearLoad(),
    resetMakesLoad(),
    updateMakesLoad(),
    editMakesLoad(),
    resetModelsLoad(),
    updateModelsLoad(),
    editModelsLoad(),
    resetColorsLoad(),
    updateColorsLoad(),
    editColorsLoad(),
    resetBodyTypeLoad(),
    updateBodyTypeLoad(),
    editBodyTypeLoad(),
    resetKilometerLoad(),
    updateKilometersLoad(),
    resetPricesLoad(),
    updatePricesLoad(),
    ResetAllFiltersLoad(),
  ]);
}
