import { GET_CREDITAPPLICATIONEXTRAS, FETCH_CREDITAPPLICATIONEXTRA, 
    DELETE_CREDITAPPLICATIONEXTRA, ADD_CREDITAPPLICATIONEXTRA, 
    EDIT_CREDITAPPLICATIONEXTRA, 
    CLEAR_CREDITAPPLICATIONEXTRA, 
    CLEAR_ALL
} from "constants/actions";

const initialState = {
    creditapplicationextras: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CREDITAPPLICATIONEXTRAS:
            return {
                ...state,
                creditapplicationextras: action.payload
            };
        case FETCH_CREDITAPPLICATIONEXTRA:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_CREDITAPPLICATIONEXTRA:
            return { ...state, [action.payload.id]: action.payload };


        case DELETE_CREDITAPPLICATIONEXTRA:
            return {
                ...state,
                creditapplicationextras: state.creditapplicationextras.filter(dealer => dealer.id !== action.payload)
            };

        case ADD_CREDITAPPLICATIONEXTRA:
            return {
                ...state,
                creditapplicationextras: [...state.creditapplicationextras, action.payload]
            };
        case CLEAR_CREDITAPPLICATIONEXTRA:
            return {
                ...state,
                creditapplicationextras: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                creditapplicationextras: []
            };
        default:
            return state;
    }
}

