import React from "react";

import { Link } from "react-router-dom";
import moment from "moment-timezone";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { LOAD_WEB_COMMENTS, SG_UPDATE_ACTIVE_PAGE } from "constants/actions";
import ReactPaginate from "react-paginate";
import { isMobile, deviceType } from "react-device-detect";

import Truncate from "react-truncate";
import Rating from "react-rating";

import GiveFeedback from "About/GiveFeedback";

import config from "_config";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Spinner,
  Fade,
  Table,
  Row,
  Col,
} from "reactstrap";

const CommentList = ({ com }) => {
  if (com) {
    return (
      <div className="div-with-light-grey-border">
        <p className="customer-name">
          {com.first_name} {com.last_name}
        </p>
        <p className="description">{com.description}</p>
        <div className=" card-stars gold-fa">
          <Rating
            initialRating={com.rating}
            name="rating"
            emptySymbol="fa fa-star-o fa-1x"
            fullSymbol="fa fa-star fa-1x"
            fractions={1}
            readonly={true}
          />
        </div>
      </div>
    );
  }
};

const List = (props) => {
  document.documentElement.classList.remove("nav-open");
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const [commentsList, setCommentsList] = React.useState([]);

  // pagination
  const [pending, setPending] = React.useState();
  const [activePage, setActivePage] = React.useState(0);
  const [pageactivePage, setPageActivePage] = React.useState();
  const [perPage, setPerPage] = React.useState(10);
  const [pageParams, setPageParams] = React.useState(props.params);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalResults, setTotalResults] = React.useState(1);
  // pagination end

  React.useEffect(() => {
    // console.log(pageactivePage)
    dispatch({ type: LOAD_WEB_COMMENTS, payload: { page: pageactivePage } });
  }, [dispatch, pageactivePage]);

  const { get_comments } = useSelector(
    (state) => ({
      get_comments: state.webcomments.webcomments,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setCommentsList(get_comments.results);
  }, [get_comments]);

  // console.log("Mobile", isMobile, deviceType)
  // console.log("Browser", isBrowser, deviceType)

  React.useEffect(() => {
    // console.log('get_activePage', get_activePage, "****************************************")
    if (get_comments) {
      setTotalResults(get_comments.count);
      if (get_comments.page_size) {
        setPerPage(get_comments.page_size);
      }
      setTotalPages(Math.ceil(get_comments.count / perPage));
    }
  }, [get_comments, perPage]);
  // console.log(user)
  const handleClick = (page) => {
    dispatch({ type: SG_UPDATE_ACTIVE_PAGE, payload: page.selected });
    setActivePage(page.selected);
    setPageActivePage(page.selected + 1);
  };

  return (
    <>
      <Row>
        {/* <Col md="12">
          <div className="d-flex justify-content-end">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakClassName={"page-item"}
              pageCount={totalPages}
              marginPagesDisplayed={isMobile ? 0 : 2}
              pageRangeDisplayed={isMobile ? 0 : 5}
              onPageChange={handleClick}
              containerClassName={"pagination"}
              subContainerClassName={"page-item pagination"}
              activeClassName={"page-item active"}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              forcePage={activePage}
            />
          </div>
        </Col> */}
        <div className={pending ? "" : "d-none mt-3"}>
          <div className="d-flex justify-content-center">
            <Fade in={pending} tag="h5">
              <Spinner
                style={{ width: "10rem", height: "10rem" }}
                type="grow"
              />
            </Fade>
          </div>
        </div>
      </Row>
      <div className={pending ? "d-none" : ""}>
        <Row className="no-gutters">
          <Col md="12">
            {commentsList
              ? commentsList.map((p, k) => <CommentList com={p} key={k} />)
              : null}
          </Col>
        </Row>
      </div>
      <Row>
        <Col md="12">
        {commentsList?

          <div className="d-flex justify-content-end">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              // breakLabel={isMobile? '': <a className="page-link">...</a>}
              breakClassName={"page-item"}
              pageCount={totalPages}
              marginPagesDisplayed={isMobile ? 0 : 2}
              pageRangeDisplayed={isMobile ? 0 : 5}
              onPageChange={handleClick}
              containerClassName={"pagination"}
              subContainerClassName={"page-item pagination"}
              activeClassName={"page-item active"}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              forcePage={activePage}
            />
          </div>
          : '' }
        </Col>
      </Row>
    </>
  );
};

export default List;
