import { combineReducers } from "redux";

// import { reducer as formReducer } from 'redux-form'; 

import leads from "./leads";
import imageprofiles from './imageprofile';
import inventories from './inventory';
import inventoryimages from './inventoryimage';
import paginate from './paginate';
import errors from "./errors";
import messages from "./messages";
import auth from "./auth";
import employees from "./employees";
import dealers from "./dealers";
import features from "./features";
import specs from "./specs";
import resetpageparams from "./resetpageparams";
import creditapplication from './creditapplication';
import creditapplicationaddress from './creditapplicationaddress';
import creditapplicationemployment from './creditapplicationemployment';
import creditapplicationextra from './creditapplicationextra';
import creditapplicationlog from './creditapplicationlog';
import creditapplicationpersonaldetail from './creditapplicationpersonaldetail';
import customer from './customer';
import inventoryselect from "./inventoryselect"
import webcontent from './webcontent';
import webheader from './webheader';
import websiteimages from './websiteimages';
import categories from './categories';
import landingpages from './landingpages';
import leadsource from './leadsource';
import selectyear from "./selectyear"
import selectmake from "./selectmake"
import selectmodel from "./selectmodel"
import selecttrim from "./selecttrim"

import blackbook from "./blackbook"

import webleads from './webleads'
import tradein from './tradein'
import leasebuster from './leasebuster'
import appointment from './appointment'
import application from './application'
// import pagefilter from './_pagefilter'

import contactus from './contactus'
import webcomments from './webcomments'


import caryears from './caryears';
import carmakes from './carmakes';
import carmodels from './carmodels';
import cartrims from './cartrims';
import carcolors from './carcolors';
import carbodytypes from './carbodytypes';
import carkilometersprice from './carkilometersprice';
import yearmake from "./yearmake";
import yearmakemodel from "./yearmakemodel";

export default combineReducers({
    auth,
    errors,
    messages,
    appointment,
    application,
    contactus,
    creditapplication,
    creditapplicationaddress,
    creditapplicationemployment,
    creditapplicationextra,
    creditapplicationlog,
    creditapplicationpersonaldetail,
    caryears,
    carmakes,
    carmodels,
    cartrims,
    carcolors,
    carbodytypes,
    carkilometersprice,
    customer,
    categories,
    dealers,
    employees,
    features,
    imageprofiles,
    inventories,
    inventoryimages,
    inventoryselect,
    leadsource,
    leasebuster,
    landingpages,
    paginate,

    leads,
    resetpageparams,
    blackbook,
    specs,
    selectyear,
    selectmake,
    selectmodel,
    selecttrim,
    tradein,
    webcomments,
    webcontent,
    webheader,
    websiteimages,
    webleads,
    yearmake,
    yearmakemodel,
});
