import React from "react";
import { Link } from "react-router-dom";

// // JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import "./nav.css";

import config from "_config";
import InventorySelectOne from "utilities/InventorySelectOne";
import SearchBox from "utilities/SearchBox";
import search from "Filter/Search"
function WhiteNavbar() {
  return (
    <>
      <Navbar expand="lg" id="navbar-main">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              id="navbar-brand"
              className="logo-image"
              to="/"
              tag={Link}
            >
              <img
                src={require("assets/images/logo/companyLogo.png")}
                height={"80px"}
                // className="img-fluid logo-image"
                alt={config.company}
              />
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              {config.company}
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>

          <Collapse navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink to="/inventory" tag={Link}>
                  Inventory
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink to="/sell" tag={Link}>
                  Sell Car
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/credit-application" tag={Link}>
                  Finance
                </NavLink>
              </NavItem>
             
              <NavItem>
                <NavLink to="/contact" tag={Link}>
                  Contact
                </NavLink>
              </NavItem>
              {/* <UncontrolledDropdown nav inNavbar> */}
              {/* <DropdownToggle className="mr-2" color="default" caret nav>
                Shop
              </DropdownToggle>
              <DropdownMenu className="dropdown-danger" right>
                <DropdownItem to="/inventory" tag={Link}>
                  Used Inventory
                </DropdownItem>

                <DropdownItem to="/test-drive" tag={Link}>
                  Book a Testdrive
                </DropdownItem>
              </DropdownMenu> */}
              {/* </UncontrolledDropdown> */}
              {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle className="mr-2" color="default" caret nav>
                Cash for Cars
              </DropdownToggle>
              <DropdownMenu className="dropdown-danger" right>
                <DropdownItem to="/sell" tag={Link}>
                  Trade Appraisal
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}

              {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle className="mr-2" color="default" caret nav>
                Finance
              </DropdownToggle>
              <DropdownMenu className="dropdown-danger" right>
                <DropdownItem to="/financial-service" tag={Link}>
                  Financial Services
                </DropdownItem>
              
                <DropdownItem to="/credit-application" tag={Link}>
                  Secure Credit Application
                </DropdownItem>
                
              </DropdownMenu>
            </UncontrolledDropdown> */}

              {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  More
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/about-us" tag={Link}>
                    About
                  </DropdownItem>
                  <DropdownItem to="/customer-feeback" tag={Link}>
                    Feedback
                  </DropdownItem>
                  <DropdownItem to="/warranty" tag={Link}>
                    Warranty
                  </DropdownItem>
                  <DropdownItem to="/team" tag={Link}>
                    Team
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}

              {/* <NavItem>
              <Button
                className="btn-round d-sm-none"
                color="danger"
                size="sm"
                to="/credit-application"
                tag={Link}
              >
                Apply for Credit
              </Button>
            </NavItem> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>

    </>
  );
}

export default WhiteNavbar;
