import React from "react";
import { Helmet } from "react-helmet";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import Calculator from "Calculator";

// import SlickSlider from "./SlickSlider";
// import ToolCards from "Home/ToolCards";
// import BlackBookSelect from "utilities/BlackBookSelect";
// // core components
// import config from "_config";
function FinancialServices() {
  document.documentElement.classList.remove("nav-open");

  return (
    <>
      <Helmet>
        <title>Bryden Financial Service</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content="Bryden 
        Financing &amp; Auto Sales, 
        your Lower Sackville area bad credit car dealer. 
        Bryden Financing &amp; Auto Sales deals in used Hyundai, 
        deals in used Toyota, deals in used Honda, deals in used Chervolet, deals in used 
        Volkswagen deals in used Ford, or get deals in used any other car"
        />
      </Helmet>
      <hr />

      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-left mb-3" md="12">
            <h1 className="blue-title">Loan Calculator</h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <h2 className="red-sub-title mb-3">Calculate Your Payments</h2>

  
                  <Calculator />
              
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default FinancialServices;
