import { put, all, call, takeLatest } from 'redux-saga/effects'
import { 
    GET_INVENTORIES_PENDING,
    GET_INVENTORIES_LOADING,
    GET_INVENTORIES,
    LOAD_INVENTORY,
    GET_MORE_INVENTORY,
    LOAD_MORE_INVENTORY,
    GET_INVENTORY_LIST,
    LOAD_INVENTORY_LIST,
    FETCH_INVENTORY_PENDING,
    FETCH_INVENTORY,
    FETCH_INVENTORY_ONE, 
    SELECT_INVENTORY,
    SG_SELECT_INVENTORY,
    APPLIED_INVENTORY,
    SG_APPLIED_INVENTORY,
    ALL_ERRORS } from "constants/actions";

import * as api from 'constants/api';

function* loadInventory(action) {
    yield put({ type: GET_INVENTORIES_PENDING });
    // console.log('action.payload', action.payload)
    try {
      // const json = yield call(api.getPagedInventories, action.payload)
      const json = yield call(api.GET_INVENTORIES, action.payload)
  
      yield put({ type: GET_INVENTORIES, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* inventoryLoad() {
    yield takeLatest(LOAD_INVENTORY, loadInventory);
  }
  
  function* loadMoreInventory(action) {
    yield put({ type: GET_INVENTORIES_LOADING });
    // console.log('action.payload', action.payload)
    try {
      // const json = yield call(api.getPagedInventories, action.payload)
      const json = yield call(api.GET_INVENTORIES, action.payload)
  
      yield put({ type: GET_MORE_INVENTORY, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* inventoryLoadMore() {
    yield takeLatest(LOAD_MORE_INVENTORY, loadMoreInventory);
  }

  function* loadInventoryList(action) {
    yield put({ type: GET_INVENTORIES_PENDING });
    // console.log('action.payload', action.payload)
    try {
      // const json = yield call(api.getPagedInventories, action.payload)
      const json = yield call(api.GET_INVENTORY_LIST, action.payload)
  
      yield put({ type: GET_INVENTORY_LIST, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* inventoryLoadList() {
    yield takeLatest(LOAD_INVENTORY_LIST, loadInventoryList);
  }

  function* fetchInventory(action) {
  
    yield put({ type: FETCH_INVENTORY_PENDING });
    try {
      const json = yield call(api.FETCH_INVENTORY, action.payload)
      yield put({ type: FETCH_INVENTORY, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* inventoryFetchOne() {
    yield takeLatest(FETCH_INVENTORY_ONE, fetchInventory);
  }

  function* selectInventory(action) {
    try {
      yield put({ type: SELECT_INVENTORY, payload: action.payload });
    } catch (e) {
      yield put({ type: ALL_ERRORS, message: e });
    }
  }
  
  export function* inventorySelectOne() {
    yield takeLatest(SG_SELECT_INVENTORY, selectInventory);
  }
  
  function* appliedInventory(action) {
    try {
      yield put({ type: APPLIED_INVENTORY, payload: action.payload });
    } catch (e) {
      yield put({ type: ALL_ERRORS, message: e });
    }
  }
  
  export function* inventoryApplied() {
    yield takeLatest(SG_APPLIED_INVENTORY, appliedInventory);
  }


  export default function* index() {
    yield all([
      inventoryLoad(), 
      inventoryLoadMore(),
      inventoryLoadList(),
      inventoryFetchOne(),
      inventorySelectOne(),
      inventoryApplied(),
    ]);
  }
