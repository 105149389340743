import { GET_LEADS,  ADD_LEAD, 
    UPDATE_LEADS, CLEAR_LEADS,  } from "constants/actions";

const initialState = {
    leads: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LEADS:
            return {
                ...state,
                leads: action.payload
            };
        
        case UPDATE_LEADS:
            return {
                ...state,
                [action.payload.id]: action.payload
            };

        // case DELETE_LEAD:
        //     return {
        //         ...state,
        //         leads: state.leads.filter(lead => lead.id !== action.payload)
        //     };
        case ADD_LEAD:
            return {
                ...state,
                leads: [...state.leads, action.payload]
            };
        case CLEAR_LEADS:
            return {
                ...state,
                leads: []
            };
        default:
            return state;
    }
}