import { ADD_FINANCE_APPLICATION, UPDATE_FINANCE_APPLICATION, CLEAR_ALL } from "constants/actions";

const initialState = {
    application: [],
    created: []
};

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_FINANCE_APPLICATION:
            return {
                ...state,
                created: action.payload
            };
        case UPDATE_FINANCE_APPLICATION:
                return {
                    ...state,
                    application: {...state.application, ...action.payload}
                };
        case CLEAR_ALL:
            return {
                ...state,
                application: []
            };
        default:
            return state;
    }
}

