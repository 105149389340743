import React from "react";
import {Helmet} from "react-helmet";
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components

import Promotions from "./Promotions.js";

import config from "_config"
import "./style.css"
function AboutUs() {
  document.documentElement.classList.remove("nav-open");
  
  return (
    <>
         <Helmet>
        <title>About Bryden Financing
          </title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content='Bryden 
        Financing &amp; Auto Sales, 
        your Lower Sackville area bad credit car dealer. 
        Bryden Financing &amp; Auto Sales deals in used Hyundai, 
        deals in used Toyota, deals in used Honda, deals in used Chervolet, deals in used 
        Volkswagen deals in used Ford, or get deals in used any other car' />
          
      </Helmet>
<hr />

          <Container>
          <Row>
              <Col className="ml-auto mr-auto text-left mb-3" md="12">
                <h1 className="blue-title">About</h1>

                <h2 className="red-sub-title mb-3">Serving Atlantic Canada Since 2003</h2>
                <p className="description">
                 Welcome to {config.company} proudly serving Atlantic Canada 
                 since 2003. Conveniently located in Lower Sackville, 
                 Nova Scotia, we offer the best selection of pre-owned 
                 vehicles with rates starting as low as 4.99%. 
                 Learn first-hand why many of our sales come are 
                 repeat customers and referrals to their friends 
                 and family. We look forward to winning your trust 
                 and business by putting you in your next pre-owned 
                 vehicle!
                </p>

          
              </Col>
            </Row>
            <Promotions />
            
            <Row>
              <Col className="ml-auto mr-auto text-left" md="12">
                <h2 className="red-sub-title mb-3">Buy with Confidence</h2>
                <p className="description">
                We only offer the best quality, pre-owned cars, trucks, and 
                SUVs at competitive prices. We have licensed Mechanics who 
                perform a motor vehicle inspection of all pre-owned vehicles 
                before they’re sold and delivered to you.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="ipad-container">
                  <img
                    alt="bryden"
                    className="img-fluid"
                    src={require("assets/images/other-images/bryden-vehicle-collage-about-640x580.jpg")}
                  />
                </div>
              </Col>
              <Col className="" md="7">
                <div >
                  <ul>
                     <li>
                      2-year Motor Vehicle Inspection on all our Pre-Owned
                      Vehicles
                    </li>
                    <li>CarProof and CarFax History Reports Available</li>
                    <li>30 Day Exchange policy</li>
                    <li>
                      Extended Warranties from Global, Coverage One and Lubrico
                    </li>
                    <li>BBB A+ Rating</li>
                    <li>In-House Financing Program</li>
                   </ul>
                </div>
              </Col>
            </Row>
           
            
            
           
          </Container>
        
     
 
    </>
  );
}

export default AboutUs;
