import { put, all, call, takeLatest } from 'redux-saga/effects'

import {LOAD_LANDINGPAGES, GET_LANDINGPAGES,
     GET_LANDINGPAGES_PENDING, ALL_ERRORS 
    } from "../constants/actions";
import * as api from '../constants/api';

function* loadLandingPage(action) {
    yield put({type: GET_LANDINGPAGES_PENDING,});
    try {
    const json = yield call(api.GET_LANDINGPAGE, action.payload)
    yield put({ type: GET_LANDINGPAGES, payload: json.data, });
    }
    catch (e) {
    yield put({type: ALL_ERRORS, error: e.message});
    }
}


export function* landingPageLoad() {
   yield takeLatest(LOAD_LANDINGPAGES, loadLandingPage);
}



export default function* index() {
    yield all([landingPageLoad(), 
    ]);
  }