import React from "react";
import { Helmet } from "react-helmet";
// react plugin used to create google maps
import ContactForm from "./ContactForm";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import "./style.css";
import config from "_config";

function ContactUs() {
  document.documentElement.classList.remove("nav-open");

  return (
    <>
        <Helmet>
        <title>
        Credit Experts Bryden Financing &amp; Auto Sales | Everyone's Approved
        </title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={`Credit Experts and Used cars in 
        ${config.city} get more deals by ${config.company}| Everyone's Approved` } />
      </Helmet>
      <hr />
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-left mb-3" md="12">
            <h1 className="blue-title">Contact</h1>

            <h2 className="red-sub-title mb-3">We Are Open</h2>
            <p className="description">
              Learn first hand why a large number of our sales are to repeat
              customers and from referrals to their friends and family. We look
              forward to earning your trust and business on your next pre-owned
              vehicle and many more to follow.
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="light-border no-gutters">
          <Col className="mr-auto" lg="6" md="6" sm="12" xs="12">
            <ContactForm />
          </Col>
          <Col md="5" className="mt-4">
            <p>
              <strong>Location</strong>
            </p>
            <p>{config.city}</p>
            {/* <p>Lower Sackville</p>
            <p>NS B4C 2R5</p> */}
            <p>
              <strong>Toll Free</strong>
            </p>
            <p>{config.tollfree}</p>
            <p>
              <strong>Telephone</strong>
            </p>
            <p>{config.phone}</p>

            <p>
              <strong>Sales Department Hours</strong>
            </p>
            <p>Monday-Friday 9AM-6PM</p>
            <p>Saturday 9AM-3PM</p>
            <p>Sunday: Closed</p>
            <p>
              <strong>Service Department Hours</strong>
            </p>
            <p>Monday-Friday 9AM-5PM</p>

            <p>Saturday/Sunday: Closed</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ContactUs;
