import { GET_CUSTOMERS, FETCH_CUSTOMER, DELETE_CUSTOMER, ADD_CUSTOMER,
     EDIT_CUSTOMER, 
    CLEAR_CUSTOMER, CLEAR_ALL,
    GET_CUSTOMERS_PENDING } from "constants/actions";
const initialState = {
    customers: [],
    pending: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CUSTOMERS_PENDING:
            return {
                ...state,
                pending: true
            }
        case GET_CUSTOMERS:
            return {
                ...state,
                customers: action.payload,
                pending: false
            };
        case FETCH_CUSTOMER:
            return { ...state, 
                [action.payload.id]: action.payload,
                pending: false };

        case EDIT_CUSTOMER:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_CUSTOMER:
            return {
                ...state,
                customers: state.customers.filter(customer => customer.id !== action.payload)
            };
        case ADD_CUSTOMER:
            return {
                ...state,
                customers: [...state.customers, action.payload]
            };
        case CLEAR_CUSTOMER:
            return {
                ...state,
                customers: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                customers: []
            };
        default:
            return state;
    }
}

