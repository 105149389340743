import { GET_CREDITAPPLICATIONADDRESSES, FETCH_CREDITAPPLICATIONADDRESS, DELETE_CREDITAPPLICATIONADDRESS, 
    ADD_CREDITAPPLICATIONADDRESS, EDIT_CREDITAPPLICATIONADDRESS, CLEAR_CREDITAPPLICATIONADDRESS, CLEAR_ALL 
} from "constants/actions";

const initialState = {
    creditapplicationaddresses: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CREDITAPPLICATIONADDRESSES:
            return {
                ...state,
                creditapplicationaddresses: action.payload
            };
        case FETCH_CREDITAPPLICATIONADDRESS:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_CREDITAPPLICATIONADDRESS:
            return { ...state, [action.payload.id]: action.payload };
  

        case DELETE_CREDITAPPLICATIONADDRESS:
            return {
                ...state,
                creditapplicationaddresses: state.creditapplicationaddresses.filter(creditapplicationaddress => creditapplicationaddress.id !== action.payload)
            };
        
        case ADD_CREDITAPPLICATIONADDRESS:
            return {
                ...state,
                creditapplicationaddresses: [...state.creditapplicationaddresses, action.payload]
            };
        case CLEAR_CREDITAPPLICATIONADDRESS:
            return {
                ...state,
                creditapplicationaddresses: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                creditapplicationaddresses: []
            };
        default:
            return state;
    }
}

