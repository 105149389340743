import { put, all, call, takeLatest } from 'redux-saga/effects'
import { LOAD_YEAR_SELECT, GET_YEAR_SELECT, 
    FETCH_YEAR_SELECT, SG_FETCH_YEAR_SELECT, 
    LOAD_MAKE_SELECT, GET_MAKE_SELECT, 
    FETCH_MAKE_SELECT, SG_FETCH_MAKE_SELECT, 
    LOAD_MODEL_SELECT, GET_MODEL_SELECT, 
    FETCH_MODEL_SELECT, SG_FETCH_MODEL_SELECT, 
    LOAD_TRIM_SELECT, GET_TRIM_SELECT, 
    FETCH_TRIM_SELECT, SG_FETCH_TRIM_SELECT, 
    // LOAD_YEAR_MAKE_MODEL_TRIM, GET_YEAR_MAKE_MODEL_TRIM,
    // FETCH_YEAR_MAKE_MODEL_TRIM, SG_FETCH_YEAR_MAKE_MODEL_TRIM,
    ALL_ERRORS } from "constants/actions";

import * as api from 'constants/api';

// Select List only 
function* selectCarYears(action) {
    try {
        const json = yield call(api.GET_CARYEARS_LIST, action.payload)
        yield put({ type: GET_YEAR_SELECT, payload: json.data, });
    }
    catch (e) {
        yield put({type: ALL_ERRORS, error: e.message});
    }
}

export function* carYearSelect() {
    yield takeLatest(LOAD_YEAR_SELECT, selectCarYears);
}

function* fetchYearSelect(action) {

    try {
        const json = yield call(api.GET_CARYEARS_LIST, action.payload)
      yield put({ type: FETCH_YEAR_SELECT, payload: json.data, });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }

  export function* yearSelectFetchOne() {
    yield takeLatest(SG_FETCH_YEAR_SELECT, fetchYearSelect);
  }


function* selectCarMakes(action) {
    try {
        const json = yield call(api.GET_CARMAKES_LIST, action.payload)
        yield put({ type: GET_MAKE_SELECT, payload: json.data, });
    }
    catch (e) {
        yield put({type: ALL_ERRORS, error: e.message});
    }
}

export function* carMakeSelect() {
    yield takeLatest(LOAD_MAKE_SELECT, selectCarMakes);
}

function* fetchMakeSelect(action) {
    try {
      const json = yield call(api.GET_CARMAKES_LIST, action.payload)
      yield put({ type: FETCH_MAKE_SELECT, payload: json.data, });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }

  export function* makeSelectFetchOne() {
    yield takeLatest(SG_FETCH_MAKE_SELECT, fetchMakeSelect);
  }

function* selectCarModels(action) {
    try {
        const json = yield call(api.GET_CARMODELS_LIST, action.payload)
        yield put({ type: GET_MODEL_SELECT, payload: json.data, });
    }
    catch (e) {
        yield put({type: ALL_ERRORS, error: e.message});
    }
}

export function* carModelSelect() {
    yield takeLatest(LOAD_MODEL_SELECT, selectCarModels);
}

function* fetchModelSelect(action) {
    try {
      const json = yield call(api.GET_CARMODELS_LIST, action.payload)
      yield put({ type: FETCH_MODEL_SELECT, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }

  export function* modelSelectFetchOne() {
    yield takeLatest(SG_FETCH_MODEL_SELECT, fetchModelSelect);
  }

function* selectCarTrims(action) {
    try {
        const json = yield call(api.GET_CARTRIMS_LIST, action.payload)
        yield put({ type: GET_TRIM_SELECT, payload: json.data, });
    }
    catch (e) {
        yield put({type: ALL_ERRORS, error: e.message});
    }
}

export function* carTrimSelect() {
    yield takeLatest(LOAD_TRIM_SELECT, selectCarTrims);
}


function* fetchTrimSelect(action) {
    try {
      const json = yield call(api.GET_CARTRIMS_LIST, action.payload)
      yield put({ type: FETCH_TRIM_SELECT, payload: json.data,});
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }

  export function* trimSelectFetchOne() {
    yield takeLatest(SG_FETCH_TRIM_SELECT, fetchTrimSelect);
  }



export default function* index() {
    yield all([carYearSelect(), yearSelectFetchOne(),
         carMakeSelect(), makeSelectFetchOne(),
        carModelSelect(), modelSelectFetchOne(),
        carTrimSelect(), trimSelectFetchOne(),
        
    ]);
  }