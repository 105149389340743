// import React from 'react';

import {
  // BrowserView,
  // MobileView,
  isBrowser,
  isMobile,
  deviceType,
  osName,
  osVersion,
  deviceDetect,
} from "react-device-detect";

const LeadSource = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const lead_source = params.get("lead-source");
  const inventory_id = params.get("inventory-id");

  const visitId = localStorage.getItem("visitId")

  const gclid = params.get("gclid")
  ? params.get("gclid")
  : localStorage.getItem("gclid")
  const pixelid = params.get("pixelid")
  ? params.get("pixelid")
  : localStorage.getItem("pixelid")
  const mssclid = params.get("mssclid")
  ? params.get("mssclid")
  : localStorage.getItem("mssclid")
  const utm_source = params.get("utm_source")
  ? params.get("utm_source")
  : localStorage.getItem("utm_medium")
  const utm_medium = params.get("utm_medium")
  ? params.get("utm_medium")
  : localStorage.getItem("utm_medium")
  const utm_campaign = params.get("utm_campaign")
  ? params.get("utm_campaign")
  : localStorage.getItem("utm_campaign")
  const campaign_code = params.get("campaign-code")
  ? params.get("campaign-code")
  : localStorage.getItem("campaign-code")


  const deviceDetected = deviceDetect();

  // getMyIp()

  const lead_source_params = {
    campaign_code,
    cookie: visitId,
    gclid,
    pixelid,
    mssclid,
    lead_source,
    utm_source,
    utm_medium,
    utm_campaign,
    isBrowser,
    isMobile,
    browser: deviceDetected.browserName,
    deviceType,
    osVersion,
    osName,
    deviceDetected,
    inventory_id,
  };

  return lead_source_params;
};

export default LeadSource;
