import React from "react";
import moment from "moment";
import noUiSlider from "nouislider";
import "nouislider/distribute/nouislider.css";
import "./slider.css";

const Slider = (props) => {

  const sliderRef = React.useRef(null);

//  console.log(props)

  React.useEffect(() => {
    noUiSlider.create(sliderRef.current, {
      start: [Math.round(props.value)],
      step: (props.step),
      tooltips: props.tooltips? true: false,
      connect: true,
      margin: props.step,
      range: {
        min: Math.round(props.min),
        max: Math.round(props.max),
      },
      
    });
    sliderRef.current.noUiSlider.on("update", props.onChange);
    sliderRef.current.noUiSlider.on("change", props.changeSlider);
  }, []);



  return (
    <div>
      <div ref={sliderRef}></div>
    </div>
  );
};

export default Slider;
