import { put, all, call, takeLatest } from 'redux-saga/effects'

import { LOAD_INVENTORY_SELECT_LIST, GET_INVENTORY_SELECT_LIST,
FETCH_INVENTORY_SELECT_LIST, SG_FETCH_INVENTORY_SELECT_LIST,
ALL_ERRORS } from "constants/actions";

import * as api from 'constants/api';

function* selectInventory(action) {
    try {
        const json = yield call(api.GET_INVENTORIES_SELECT_LIST, action.payload)
        yield put({ type: GET_INVENTORY_SELECT_LIST, payload: json.data, });
    }
    catch (e) {
        yield put({type: ALL_ERRORS, error: e.message});
    }
}

export function* InventorySelect() {
    yield takeLatest(LOAD_INVENTORY_SELECT_LIST, selectInventory);
}

function* fetchYearSelect(action) {

    try {
        const json = yield call(api.GET_INVENTORIES_SELECT_LIST, action.payload)
      yield put({ type: FETCH_INVENTORY_SELECT_LIST, payload: json.data, });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }

  export function* InventoryFetchOne() {
    yield takeLatest(SG_FETCH_INVENTORY_SELECT_LIST, fetchYearSelect);
  }

  export default function* index() {
    yield all([InventorySelect(), InventoryFetchOne(),
    ]);
  }