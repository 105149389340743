import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from "./reducers";
import { rootSaga } from './sagas/index'

// import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
// import { logger } from 'redux-logger';

// import LogRocket from 'logrocket';

// this where we initialize state
const initialState = {};

const sagaMiddleware = createSagaMiddleware()

// const store = createStore(
//     rootReducer,
//     initialState,
//     composeWithDevTools(
//       applyMiddleware(sagaMiddleware, logger)
//     ),
// );

const store = createStore(
  rootReducer,
  initialState,
  (
    applyMiddleware(sagaMiddleware)
  ),
);

sagaMiddleware.run(rootSaga)

export default store;