import React from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";
import Rating from "react-rating";

import { LOAD_WEB_COMMENTS } from "constants/actions";

import GiveFeedback from "About/GiveFeedback";
import FeedbackList from "About/FeedbackList";
import config from "_config";

import "./style.css";

const CustomerFeeback = () => {
  document.documentElement.classList.remove("nav-open");

  return (
    <>
      <Helmet>
        <title>
        Customer Reviews of Bryden Financing &amp; Auto Sales
        </title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={`Customer Reviews of ${config.company}` } />
      </Helmet>
      <hr />

      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-left mb-3" md="12">
            <h1 className="blue-title">Feedback</h1>

            <h2 className="red-sub-title mb-3">
              Our Goal is to Deliver A Stress-Free Purchasing Experience
            </h2>
            <p className="description">
            Please take a moment and read some online reviews from 
            previous customers sharing their car buying experiences. 
            We look forward to earning your trust while we put you in 
            your next pre-owned vehicle!
            </p>

            
          </Col>
        </Row>

        <Row>
          <Col>
          <h2 className="red-sub-title mb-3">
          We want to hear from you!
      </h2>
          </Col>
        </Row>
        <FeedbackList />
      </Container>
      

      <GiveFeedback heading={"We want to hear from you!"} />
    </>
  );
};

export default CustomerFeeback;
