import { GET_CREDITAPPLICATIONLOGS, FETCH_CREDITAPPLICATIONLOG, 
    DELETE_CREDITAPPLICATIONLOG, ADD_CREDITAPPLICATIONLOG, 
    EDIT_CREDITAPPLICATIONLOG, 
    CLEAR_CREDITAPPLICATIONLOG, 
    CLEAR_ALL
} from "constants/actions";

const initialState = {
    creditapplicationlogs: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CREDITAPPLICATIONLOGS:
            return {
                ...state,
                creditapplicationlogs: action.payload
            };
        case FETCH_CREDITAPPLICATIONLOG:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_CREDITAPPLICATIONLOG:
            return { ...state, [action.payload.id]: action.payload };


        case DELETE_CREDITAPPLICATIONLOG:
            return {
                ...state,
                creditapplicationlogs: state.creditapplicationlogs.filter(creditapplicationlog => creditapplicationlog.id !== action.payload)
            };

        case ADD_CREDITAPPLICATIONLOG:
            return {
                ...state,
                creditapplicationlogs: [...state.creditapplicationlogs, action.payload]
            };
        case CLEAR_CREDITAPPLICATIONLOG:
            return {
                ...state,
                creditapplicationlogs: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                creditapplicationlogs: []
            };
        default:
            return state;
    }
}

