import { GET_EMPLOYEES, DELETE_EMPLOYEE, ADD_EMPLOYEE, 
    CLEAR_EMPLOYEE, CLEAR_ALL, FETCH_EMPLOYEE, 
    EDIT_EMPLOYEE } from "constants/actions";

const initialState = {
    employees: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_EMPLOYEES:
            return {
                ...state,
                employees: action.payload
            };

        case FETCH_EMPLOYEE:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_EMPLOYEE:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_EMPLOYEE:
            return {
                ...state,
                employees: state.employees.filter(employee => employee.id !== action.payload)
            };
        case ADD_EMPLOYEE:
            return {
                ...state,
                employees: [...state.employees, action.payload]
            };
        case CLEAR_EMPLOYEE:
            return {
                ...state,
                employees: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                employees: []
            };
        default:
            return state;
    }
}

// import _ from 'lodash'
// import { GET_EMPLOYEES, DELETE_EMPLOYEE, ADD_EMPLOYEE, CLEAR_EMPLOYEE, FETCH_EMPLOYEE, EDIT_EMPLOYEE } from "../actions/types.js";

// export default (state = {}, action) => {
//     switch (action.type) {
//         case GET_EMPLOYEES:
//             console.log(`Reducer: ${JSON.stringify(state)}`)
//             return { ...state, ..._.mapKeys(action.payload, 'id') }
//         case FETCH_EMPLOYEE:
//             return { ...state, [action.payload.id]: action.payload };
//         case ADD_EMPLOYEE:
//             return { ...state, [action.payload.id]: action.payload };
//         case EDIT_EMPLOYEE:
//             return { ...state, [action.payload.id]: action.payload };
//         case CLEAR_EMPLOYEE:
//             return {...state, employee: [] };
//         case DELETE_EMPLOYEE:
//             // payload is the id for delete
//             return _.omit(state, action.payload)
//         default:
//             return state;
//     }
// }