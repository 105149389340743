import React, { useState } from "react";
import { Row, Col } from "reactstrap";

const Calculate = ({
  interestValue,
  amountValue,
  yearsValue,
  downPayment,
  tradeinValue,
  updateValue,
}) => {
  // if (localStorage.getItem("interestValue")){
  //     interestValue = localStorage.getItem("interestValue")
  // }

  // if (updateValue) {
  //     localStorage.setItem("amountValue", amountValue);
  // } else {
  //     amountValue = localStorage.getItem("amountValue")
  // }
  // if (localStorage.getItem("yearsValue")){
  //     yearsValue = localStorage.getItem("yearsValue")
  // }
  // if (localStorage.getItem("downPayment")){
  //     downPayment = localStorage.getItem("downPayment")
  // }
  // if (localStorage.getItem("tradeinValue")){
  //     tradeinValue = localStorage.getItem("tradeinValue")
  // }

  const [financeValue, setfinanceValue] = useState(23390);
  React.useEffect(() => {
    setfinanceValue(
      parseFloat(amountValue) -
        (parseFloat(downPayment) + parseFloat(tradeinValue))
    );
  }, [amountValue, tradeinValue, downPayment]);

  let currAPR = interestValue / 100 / 100;
  // theApr = currAPR
  const decimalFormat = currAPR + 1;
  const totalOwed = decimalFormat * financeValue;
  const monthlyRepayment = totalOwed / (yearsValue * 12);
  const biWeeklyRepayment = totalOwed / (yearsValue * 26);
  const weeklyRepayment = totalOwed / (yearsValue * 52);

  // console.log("interestValue", interestValue)
  // console.log("amountValue", amountValue)
  // console.log("yearsValue", yearsValue)
  // console.log("downPayment", downPayment)
  // console.log("tradeinValue", tradeinValue)

  return (
    <Row>
      <Col xs="4">
        <div className="card-stats text-center">
          <span className="value-highlight">
            ${Math.round(weeklyRepayment)}
          </span>
          <br />
          <small> / Weekly</small>
        </div>
      </Col>
      <Col xs="4">
        <div className="card-stats text-center">
          <span className="value-highlight">
            ${Math.round(biWeeklyRepayment)}
          </span>
          <br />
          <small> / Bi-Weekly</small>
        </div>
      </Col>
      <Col xs="4">
        <div className="card-stats text-center">
          <span className="value-highlight">
            ${Math.round(monthlyRepayment)}
          </span>
          <br />
          <small> / Monthly</small>
        </div>
      </Col>
    </Row>
  );
};

export default Calculate;
