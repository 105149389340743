import { GET_FEATURES, DELETE_FEATURE, ADD_FEATURE, 
    CLEAR_ALL, FETCH_FEATURE, 
    EDIT_FEATURE } from "constants/actions";
import _ from 'lodash'
const initialState = {
    features: []
};
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_FEATURES:
            // console.log(`Reducer: ${JSON.stringify(state)}`)
            // return { ...state, ..._.mapKeys(action.payload, 'id') }
            return {...state,features: action.payload};
        case FETCH_FEATURE:
            return { ...state, [action.payload.id]: action.payload };
        case ADD_FEATURE:
            return { ...state, [action.payload.id]: action.payload };
        case EDIT_FEATURE:
            return { ...state, [action.payload.id]: action.payload };
        case CLEAR_ALL:
            return { ...state, features: [] };
        case DELETE_FEATURE:
            // payload is the id for delete
            return _.omit(state, action.payload)
        default:
            return state;
    }
}