import Config from "./_config.json"
const production = 'https://api.preautoco.com/api/web'
const staging = 'https://api.datastruk.com/api/web'
const  local = 'http://127.0.0.1:8000/api/web'

const config = {
    googleKey: 'AIzaSyBeFwFPIB8G91Ye3f-p3t_zmz8MjvidgcI',
    loginproduction: 'https://api.preautoco.com/api/customer',
    loginlocal: 'http://127.0.0.1:8000/api/customer',
    loginapi: 'https://api.preautoco.com/api/customer',

    serverapi: `${production}`,
    
    serverurl: Config.filter((x) => x.name === "serverurl")[0].value,

    apikey: Config.filter((x) => x.name === "apikey")[0].value,
    portalkey: Config.filter((x) => x.name === "portalkey")[0].value,
    company: Config.filter((x) => x.name === "company")[0].value,
    website: Config.filter((x) => x.name === "website")[0].value,
    short_co: 'Demo4',
    address: Config.filter((x) => x.name === "address")[0].value,
    city: Config.filter((x) => x.name === "city")[0].value,
    province: Config.filter((x) => x.name === "province")[0].value,
    pincode: Config.filter((x) => x.name === "pincode")[0].value,
    phone: Config.filter((x) => x.name === "phone")[0].value,
    fax: Config.filter((x) => x.name === "fax")[0].value,
    tollfree: Config.filter((x) => x.name === "tollfree")[0].value,
    emailid: Config.filter((x) => x.name === "emailid")[0].value,
    map_zoom: Config.filter((x) => x.name === "map_zoom")[0].value,
    lng: Config.filter((x) => x.name === "lng")[0].value,
    lat: Config.filter((x) => x.name === "lat")[0].value,
    web_lead_stage: Config.filter((x) => x.name === "web_lead_stage")[0].value,
    web_lead_source: Config.filter((x) => x.name === "web_lead_source")[0].value,
    web_lead_type_trade_in: Config.filter((x) => x.name === "web_lead_type_trade_in")[0].value,
    web_lead_type_price_enquiry: Config.filter((x) => x.name === "web_lead_type_price_enquiry")[0].value,
    web_lead_type_credit_app: Config.filter((x) => x.name === "web_lead_type_credit_app")[0].value,
    web_lead_type_interested: Config.filter((x) => x.name === "web_lead_type_interested")[0].value,
    web_lead_type_test_drive: Config.filter((x) => x.name === "web_lead_type_test_drive")[0].value,
    web_lead_type_feedback: Config.filter((x) => x.name === "web_lead_type_feedback")[0].value,
    web_lead_type_contact: Config.filter((x) => x.name === "web_lead_type_contact")[0].value,
    web_lead_type_email: Config.filter((x) => x.name === "web_lead_type_email")[0].value,
    terms_updated: '2020-05-21',
    privacy_updated: '2020-05-21',
    // change this logo image
    logo: 'assets/images/logo/company-logo.png',
    homepage_video: 'https://www.youtube.com/embed/1WmNkREVqOI',
    // https://www.youtube.com/embed/RcmrbNRK-jY
    // homepage_video: 'https://www.youtube.com/watch?v=RcmrbNRK-jY',
};
export default config