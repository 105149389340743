export const ALL_ERRORS = "ALL_ERRORS";
export const CLEAR_ALL ='CLEAR_ALL';
export const ERASE_ALL ='ERASE_ALL';

export const GET_CONFIG = "GET_CONFIG";
export const LOAD_CONFIG = "LOAD_CONFIG";
export const FETCH_CONFIG = "FETCH_CONFIG";
export const UPDATE_CONFIG = "UPDATE_CONFIG";
export const CLEAR_CONFIG = "CLEAR_CONFIG";

export const GET_LEADS = "GET_LEADS";
export const DELETE_LEAD = "DELETE_LEAD";
export const ADD_LEAD = "ADD_LEAD";
export const CREATE_LEAD = "CREATE_LEAD";
export const UPDATE_LEADS = 'UPDATE_LEADS';
export const SG_UPDATE_LEADS = 'SG_UPDATE_LEADS';
export const CLEAR_LEADS = 'CLEAR_LEADS';
// 
export const GET_FEATURES = "GET_FEATURES";
export const DELETE_FEATURE = "DELETE_FEATURE";
export const ADD_FEATURE = "ADD_FEATURE";
export const FETCH_FEATURE = "FETCH_FEATURE";
export const UPDATE_FEATURES = 'UPDATE_FEATURES';
export const EDIT_FEATURE = 'EDIT_FEATURE';
export const CLEAR_FEATURES = 'CLEAR_FEATURES';
// 
export const GET_CATEGORIES = "GET_CATEGORIES";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const CLEAR_CATEGORY = 'CLEAR_CATEGORY';
// 
export const GET_SPECS = "GET_SPECS";
export const DELETE_SPEC = "DELETE_SPEC";
export const ADD_SPEC = "ADD_SPEC";
export const FETCH_SPEC = "FETCH_SPEC";
export const UPDATE_SPECS = 'UPDATE_SPECS';
export const EDIT_SPEC = 'EDIT_SPEC'
export const CLEAR_SPECS = 'CLEAR_SPECS';
//
export const GET_INVENTORIES_PENDING = "GET_INVENTORIES_PENDING"
export const GET_INVENTORIES_LOADING = "GET_INVENTORIES_LOADING"
export const GET_INVENTORIES = "GET_INVENTORIES"
export const LOAD_INVENTORY = "LOAD_INVENTORY"

export const SELECT_INVENTORY = "SELECT_INVENTORY"
export const SG_SELECT_INVENTORY = "SG_SELECT_INVENTORY"
export const APPLIED_INVENTORY = "APPLIED_INVENTORY"
export const SG_APPLIED_INVENTORY = "SG_APPLIED_INVENTORY"

export const GET_MORE_INVENTORY = "GET_MORE_INVENTORY"
export const LOAD_MORE_INVENTORY = "LOAD_MORE_INVENTORY"

export const FETCH_INVENTORY_PENDING = "FETCH_INVENTORY_PENDING"
export const FETCH_INVENTORY = "FETCH_INVENTORY"
export const FETCH_INVENTORY_ONE = "FETCH_INVENTORY_ONE"
//
export const GET_INVENTORY_LIST = "GET_INVENTORY_LIST"
export const LOAD_INVENTORY_LIST = "LOAD_INVENTORY_LIST"
//
export const GET_INVENTORY_SELECT = "GET_INVENTORY_SELECT";
export const DELETE_INVENTORY = "DELETE_INVENTORY";
export const ADD_INVENTORY = "ADD_INVENTORY";
export const CLEAR_INVENTORY = "CLEAR_INVENTORY";

export const EDIT_INVENTORY = "EDIT_INVENTORY";


// 
export const GET_INVENTORYIMAGES = "GET_INVENTORYIMAGES";
export const DELETE_INVENTORYIMAGE = "DELETE_INVENTORYIMAGE";
export const ADD_INVENTORYIMAGE = "ADD_INVENTORYIMAGE";
export const CLEAR_INVENTORYIMAGE = "CLEAR_INVENTORYIMAGE";
export const FETCH_INVENTORYIMAGE = "FETCH_INVENTORYIMAGE";
export const EDIT_INVENTORYIMAGE = "EDIT_INVENTORYIMAGE";
//
export const GET_IMAGEPROFILES = "GET_IMAGEPROFILES";
export const DELETE_IMAGEPROFILE = "DELETE_IMAGEPROFILE";
export const ADD_IMAGEPROFILE = "ADD_IMAGEPROFILE";
export const CLEAR_IMAGEPROFILE = "CLEAR_IMAGEPROFILE";
export const FETCH_IMAGEPROFILE = "FETCH_IMAGEPROFILE";
export const EDIT_IMAGEPROFILE = "EDIT_IMAGEPROFILE";
//
export const GET_WEBSITEIMAGES = "GET_WEBSITEIMAGES";
export const DELETE_WEBSITEIMAGE = "DELETE_WEBSITEIMAGE";
export const ADD_WEBSITEIMAGE = "ADD_WEBSITEIMAGE";
export const CLEAR_WEBSITEIMAGE = "CLEAR_WEBSITEIMAGE";
export const FETCH_WEBSITEIMAGE = "FETCH_WEBSITEIMAGE";
export const EDIT_WEBSITEIMAGE = "EDIT_WEBSITEIMAGE";
//
export const LOAD_WEBCONTENT = "LOAD_WEBCONTENT";
export const GET_WEBCONTENTS = "GET_WEBCONTENTS";
export const DELETE_WEBCONTENT = "DELETE_WEBCONTENT";
export const ADD_WEBCONTENT = "ADD_WEBCONTENT";
export const CLEAR_WEBCONTENT = "CLEAR_WEBCONTENT";
export const FETCH_WEBCONTENT = "FETCH_WEBCONTENT";
export const SG_FETCH_WEBCONTENT = "SG_FETCH_WEBCOMMENT";
export const EDIT_WEBCONTENT = "EDIT_WEBCONTENT";
//
export const GET_WEBHEADERS = "GET_WEBHEADERS";
export const DELETE_WEBHEADER = "DELETE_WEBHEADER";
export const ADD_WEBHEADER = "ADD_WEBHEADER";
export const CLEAR_WEBHEADER = "CLEAR_WEBHEADER";
export const FETCH_WEBHEADER = "FETCH_WEBHEADER";
export const EDIT_WEBHEADER = "EDIT_WEBHEADER";
//
export const GET_LANDINGPAGES = "GET_LANDINGPAGES";
export const LOAD_LANDINGPAGES = "LOAD_LANDINGPAGES";
export const DELETE_LANDINGPAGE = "DELETE_LANDINGPAGE";
export const ADD_LANDINGPAGE = "ADD_LANDINGPAGE";
export const CLEAR_LANDINGPAGE = "CLEAR_LANDINGPAGE";
export const FETCH_LANDINGPAGE = "FETCH_LANDINGPAGE";
export const EDIT_LANDINGPAGE = "EDIT_LANDINGPAGE";
export const ADD_LANDINGPAGE_IMAGE = "ADD_LANDINGPAGE_IMAGE";
export const GET_LANDINGPAGES_PENDING = 'GET_LANDINGPAGES_PENDING'; 
//
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const LOAD_EMPLOYEES = "LOAD_EMPLOYEES";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const CLEAR_EMPLOYEE = "CLEAR_EMPLOYEE";
export const FETCH_EMPLOYEE = "FETCH_EMPLOYEE";
export const EDIT_EMPLOYEE = "EDIT_EMPLOYEE";
export const ADD_EMPLOYEE_PIC = "ADD_EMPLOYEE_PIC";
//
export const DEALER_WEBSITE_REQUESTED = "DEALER_WEBSITE_REQUESTED";
export const GET_DEALERS = "GET_DEALERS";
export const GET_DEALERS_FAILED = "GET_DEALERS_FAILED";
export const DELETE_DEALER = "DELETE_DEALER";
export const ADD_DEALER = "ADD_DEALER";
export const CLEAR_DEALER = "CLEAR_DEALER";
export const FETCH_DEALER = "FETCH_DEALER";
export const EDIT_DEALER = "EDIT_DEALER";
export const LOAD_DEALER = "LOAD_DEALER";
export const ADD_DEALER_LOGO = "ADD_DEALER_LOGO";
//
export const LOAD_GARAGE = "LOAD_GARAGE";
export const GET_GARAGES = "GET_GARAGES";
export const DELETE_GARAGE = "DELETE_GARAGE";
export const ADD_GARAGE = "ADD_GARAGE";
export const CLEAR_GARAGE = "CLEAR_GARAGE";
export const FETCH_GARAGE = "FETCH_GARAGE";
export const EDIT_GARAGE = "EDIT_GARAGE";
//
export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
//
export const GET_LEAD_SOURCE = "GET_LEAD_SOURCE";
export const LOAD_LEAD_SOURCE = "LOAD_LEAD_SOURCE";
export const UPDATE_LEAD_SOURCE = "UPDATE_LEAD_SOURCE";
export const SG_UPDATE_LEAD_SOURCE = "SG_UPDATE_LEAD_SOURCE";
// 
export const GET_ACTIVE_PAGE = "GET_ACTIVE_PAGE";
export const SG_GET_ACTIVE_PAGE = "SG_GET_ACTIVE_PAGE";
export const LOAD_ACTIVE_PAGE = "LOAD_ACTIVE_PAGE";
export const UPDATE_ACTIVE_PAGE = "UPDATE_ACTIVE_PAGE";
export const SG_UPDATE_ACTIVE_PAGE = "SG_UPDATE_ACTIVE_PAGE";
export const ADD_ACTIVE_PAGE = "ADD_ACTIVE_PAGE";
//
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOAD_REQUEST = "LOAD_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const CONFIRM_RESET_PASSWORD_SUCCESS = "CONFIRM_RESET_PASSWORD_SUCCESS";
export const CONFIRM_RESET_PASSWORD_FAIL = "CONFIRM_RESET_PASSWORD_FAIL";

export const CLEAR_PASSWORD_DATA = 'CLEAR_PASSWORD_DATA';
//
export const LOAD_CUSTOMER = "LOAD_CUSTOMER";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const FETCH_CUSTOMER = "FETCH_CUSTOMER";
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER'
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';
export const GET_CUSTOMERS_PENDING = 'GET_CUSTOMERS_PENDING'; 
//
export const LOAD_SERVICE_REQUESTS = "LOAD_SERVICE_REQUESTS";
export const GET_SERVICE_REQUESTS = "GET_SERVICE_REQUESTS";
export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST";
export const ADD_SERVICE_REQUEST = "ADD_SERVICE_REQUEST";
export const FETCH_SERVICE_REQUEST = "FETCH_SERVICE_REQUEST";
export const UPDATE_SERVICE_REQUEST = 'UPDATE_SERVICE_REQUEST';
export const EDIT_SERVICE_REQUEST = 'EDIT_SERVICE_REQUEST'
export const CLEAR_SERVICE_REQUEST = 'CLEAR_SERVICE_REQUEST'; 
export const GET_SERVICE_REQUEST_PENDING = 'GET_SERVICE_REQUEST_PENDING'; 
//
export const GET_CREDITAPPLICATIONS = "GET_CREDITAPPLICATIONS";
export const DELETE_CREDITAPPLICATION = "DELETE_CREDITAPPLICATION";
export const ADD_CREDITAPPLICATION = "ADD_CREDITAPPLICATION";
export const FETCH_CREDITAPPLICATION = "FETCH_CREDITAPPLICATION";
export const UPDATE_CREDITAPPLICATION = 'UPDATE_CREDITAPPLICATION';
export const EDIT_CREDITAPPLICATION = 'EDIT_CREDITAPPLICATION'
export const CLEAR_CREDITAPPLICATION = 'CLEAR_CREDITAPPLICATION';
export const GET_CREDITAPPLICATIONS_PENDING = 'GET_CREDITAPPLICATIONS_PENDING'; 
//
export const GET_CREDITAPPLICATIONEXTRAS = "GET_CREDITAPPLICATIONEXTRAS";
export const DELETE_CREDITAPPLICATIONEXTRA = "DELETE_CREDITAPPLICATIONEXTRA";
export const ADD_CREDITAPPLICATIONEXTRA = "ADD_CREDITAPPLICATIONEXTRA";
export const FETCH_CREDITAPPLICATIONEXTRA = "FETCH_CREDITAPPLICATIONEXTRA";
export const UPDATE_CREDITAPPLICATIONEXTRA = 'UPDATE_CREDITAPPLICATIONEXTRA';
export const EDIT_CREDITAPPLICATIONEXTRA = 'EDIT_CREDITAPPLICATIONEXTRA'
export const CLEAR_CREDITAPPLICATIONEXTRA = 'CLEAR_CREDITAPPLICATIONEXTRA';
//
export const GET_CREDITAPPLICATIONADDRESSES = "GET_CREDITAPPLICATIONADDRESSES";
export const DELETE_CREDITAPPLICATIONADDRESS = "DELETE_CREDITAPPLICATIONADDRESS";
export const ADD_CREDITAPPLICATIONADDRESS = "ADD_CREDITAPPLICATIONADDRESS";
export const FETCH_CREDITAPPLICATIONADDRESS = "FETCH_CREDITAPPLICATIONADDRESS";
export const UPDATE_CREDITAPPLICATIONADDRESS = 'UPDATE_CREDITAPPLICATIONADDRESS';
export const EDIT_CREDITAPPLICATIONADDRESS = 'EDIT_CREDITAPPLICATIONADDRESS'
export const CLEAR_CREDITAPPLICATIONADDRESS = 'CLEAR_CREDITAPPLICATIONADDRESS';
//
export const GET_CREDITAPPLICATIONEMPLOYMENTS = "GET_CREDITAPPLICATIONEMPLOYMENTS";
export const DELETE_CREDITAPPLICATIONEMPLOYMENT = "DELETE_CREDITAPPLICATIONEMPLOYMENT";
export const ADD_CREDITAPPLICATIONEMPLOYMENT = "ADD_CREDITAPPLICATIONEMPLOYMENT";
export const FETCH_CREDITAPPLICATIONEMPLOYMENT = "FETCH_CREDITAPPLICATIONEMPLOYMENT";
export const UPDATE_CREDITAPPLICATIONEMPLOYMENT = 'UPDATE_CREDITAPPLICATIONEMPLOYMENT';
export const EDIT_CREDITAPPLICATIONEMPLOYMENT = 'EDIT_CREDITAPPLICATIONEMPLOYMENT'
export const CLEAR_CREDITAPPLICATIONEMPLOYMENT = 'CLEAR_CREDITAPPLICATIONEMPLOYMENT';
//
export const GET_CREDITAPPLICATIONPERSONALDETAILS = "GET_CREDITAPPLICATIONPERSONALDETAILS";
export const DELETE_CREDITAPPLICATIONPERSONALDETAIL = "DELETE_CREDITAPPLICATIONPERSONALDETAIL";
export const ADD_CREDITAPPLICATIONPERSONALDETAIL = "ADD_CREDITAPPLICATIONPERSONALDETAIL";
export const FETCH_CREDITAPPLICATIONPERSONALDETAIL = "FETCH_CREDITAPPLICATIONPERSONALDETAIL";
export const UPDATE_CREDITAPPLICATIONPERSONALDETAIL = 'UPDATE_CREDITAPPLICATIONPERSONALDETAIL';
export const EDIT_CREDITAPPLICATIONPERSONALDETAIL = 'EDIT_CREDITAPPLICATIONPERSONALDETAIL'
export const CLEAR_CREDITAPPLICATIONPERSONALDETAIL = 'CLEAR_CREDITAPPLICATIONPERSONALDETAIL';
//
export const GET_CREDITAPPLICATIONLOGS = "GET_CREDITAPPLICATIONLOGS";
export const DELETE_CREDITAPPLICATIONLOG = "DELETE_CREDITAPPLICATIONLOG";
export const ADD_CREDITAPPLICATIONLOG = "ADD_CREDITAPPLICATIONLOG";
export const FETCH_CREDITAPPLICATIONLOG = "FETCH_CREDITAPPLICATIONLOG";
export const UPDATE_CREDITAPPLICATIONLOG = 'UPDATE_CREDITAPPLICATIONLOG';
export const EDIT_CREDITAPPLICATIONLOG = 'EDIT_CREDITAPPLICATIONLOG'
export const CLEAR_CREDITAPPLICATIONLOG = 'CLEAR_CREDITAPPLICATIONLOG';
export const GET_CREDITAPPLICATIONLOGS_PENDING = 'GET_CREDITAPPLICATIONLOGS_PENDING'; 
//

export const LOAD_CARYEARS = "LOAD_CARYEARS"
export const GET_CARYEARS = "GET_CARYEARS";
export const DELETE_CARYEAR = "DELETE_CARYEAR";
export const ADD_CARYEAR = "ADD_CARYEAR";
export const FETCH_CARYEAR = "FETCH_CARYEAR";
export const UPDATE_CARYEAR = 'UPDATE_CARYEAR';
export const EDIT_CARYEAR = 'EDIT_CARYEAR'
export const CLEAR_CARYEAR = 'CLEAR_CARYEAR';
//

export const LOAD_CARMAKES = "LOAD_CARMAKES";
export const GET_CARMAKES = "GET_CARMAKES";
export const DELETE_CARMAKE = "DELETE_CARMAKE";
export const ADD_CARMAKE = "ADD_CARMAKE";
export const FETCH_CARMAKE = "FETCH_CARMAKE";
export const UPDATE_CARMAKE = 'UPDATE_CARMAKE';
export const EDIT_CARMAKE = 'EDIT_CARMAKE'
export const CLEAR_CARMAKE = 'CLEAR_CARMAKE';
//

export const LOAD_CARMODELS = "LOAD_CARMODELS"
export const GET_CARMODELS = "GET_CARMODELS";
export const DELETE_CARMODEL = "DELETE_CARMODEL";
export const ADD_CARMODEL = "ADD_CARMODEL";
export const FETCH_CARMODEL = "FETCH_CARMODEL";
export const UPDATE_CARMODEL = 'UPDATE_CARMODEL';
export const EDIT_CARMODEL = 'EDIT_CARMODEL'
export const CLEAR_CARMODEL = 'CLEAR_CARMODEL';
//
export const LOAD_CARTRIMS = "LOAD_CARTRIMS";
export const GET_CARTRIMS = "GET_CARTRIMS";
export const DELETE_CARTRIM = "DELETE_CARTRIM";
export const ADD_CARTRIM = "ADD_CARTRIM";
export const FETCH_CARTRIM = "FETCH_CARTRIM";
export const UPDATE_CARTRIM = 'UPDATE_CARTRIM';
export const EDIT_CARTRIM = 'EDIT_CARTRIM'
export const CLEAR_CARTRIM = 'CLEAR_CARTRIM';
//
export const LOAD_CARCOLORS = "LOAD_CARCOLORS";
export const GET_CARCOLORS = "GET_CARCOLORS";
export const DELETE_CARCOLOR = "DELETE_CARCOLOR";
export const ADD_CARCOLOR = "ADD_CARCOLOR";
export const FETCH_CARCOLOR = "FETCH_CARCOLOR";
export const UPDATE_CARCOLOR = 'UPDATE_CARCOLOR';
export const EDIT_CARCOLOR = 'EDIT_CARCOLOR'
export const CLEAR_CARCOLOR = 'CLEAR_CARCOLOR';
//
export const LOAD_CARBODYTYPES = "LOAD_CARBODYTYPES";
export const GET_CARBODYTYPES = "GET_CARBODYTYPES";
export const DELETE_CARBODYTYPE = "DELETE_CARBODYTYPE";
export const ADD_CARBODYTYPE = "ADD_CARBODYTYPE";
export const FETCH_CARBODYTYPE = "FETCH_CARBODYTYPE";
export const UPDATE_CARBODYTYPE = 'UPDATE_CARBODYTYPE';
export const EDIT_CARBODYTYPE = 'EDIT_CARBODYTYPE'
export const CLEAR_CARBODYTYPE = 'CLEAR_CARBODYTYPE';
//
export const LOAD_KM_PRICES = "LOAD_KM_PRICES";
export const GET_KM_PRICES = "GET_KM_PRICES";
export const DELETE_KM_PRICE = "DELETE_KM_PRICE";
export const ADD_KM_PRICE = "ADD_KM_PRICE";
export const FETCH_KM_PRICE = "FETCH_KM_PRICE";
export const UPDATE_KM_PRICE = 'UPDATE_KM_PRICE';
export const EDIT_KM_PRICE = 'EDIT_KM_PRICE'
export const CLEAR_KM_PRICE = 'CLEAR_KM_PRICE';
//
export const CLEAR_YEARS = "CLEAR_YEARS"
export const RESET_YEARS = "RESET_YEARS";
export const SET_YEARS = "SET_YEARS"
export const ADD_YEARS = "ADD_YEARS";
export const DELETE_YEARS = "DELETE_YEARS"
export const REMOVE_YEARS = "REMOVE_YEARS";

export const CLEAR_MODELS = "CLEAR_MODELS"
export const RESET_MODELS = 'RESET_MODELS';
export const SET_MODELS = "SET_MODELS"
export const ADD_MODELS = "ADD_MODELS"
export const DELETE_MODELS = "DELETE_MODELS"
export const REMOVE_MODELS = "REMOVE_MODELS";

export const CLEAR_MAKES = "CLEAR_MAKES"
export const RESET_MAKES = 'RESET_MAKES'
export const SET_MAKES = "SET_MAKES"
export const ADD_MAKES = "ADD_MAKES"
export const DELETE_MAKES = "DELETE_MAKES"
export const REMOVE_MAKES = "REMOVE_MAKES";

export const CLEAR_COLORS = "CLEAR_COLORS"
export const RESET_COLORS = "RESET_COLORS"
export const SET_COLORS = "SET_COLORS"
export const ADD_COLORS = "ADD_COLORS"
export const DELETE_COLORS = "DELETE_COLORS"
export const REMOVE_COLORS = "REMOVE_COLORS"
//
export const CLEAR_BODYTYPE = "CLEAR_BODYTYPE"
export const RESET_BODYTYPE = "RESET_BODYTYPE"
export const SET_BODYTYPE = "SET_BODYTYPE"
export const ADD_BODYTYPE = "ADD_BODYTYPE"
export const DELETE_BODYTYPE = "DELETE_BODYTYPE"
export const REMOVE_BODYTYPE = "REMOVE_BODYTYPE";
//
export const CLEAR_KILOMETER = "CLEAR_KILOMETER"
export const RESET_KILOMETER = "RESET_KILOMETER"
export const SET_KILOMETERS = "SET_KILOMETERS"
export const ADD_KILOMETERS = "ADD_KILOMETERS"
//
export const CLEAR_PRICE = "CLEAR_PRICE"
export const RESET_PRICE = "RESET_PRICE"
export const SET_PRICES = "SET_PRICES"
export const ADD_PRICES = "ADD_PRICES"
//
export const CLEAR_SEARCH = "CLEAR_SEARCH"
export const RESET_SEARCH = "RESET_SEARCH"
export const SET_SEARCH = "SET_SEARCH"
export const ADD_SEARCH = "ADD_SEARCH"
export const DELETE_SEARCH = "DELETE_SEARCH"
export const REMOVE_SEARCH = "REMOVE_SEARCH";
//
export const RESET_ALL_PARAM = "RESET_ALL_PARAM"
export const SG_RESET_ALL_PARAM = "SG_RESET_ALL_PARAM"
//
export const GET_YEAR_MAKE_MODEL_TRIM_PENDING = "GET_YEAR_MAKE_MODEL_TRIM_PENDING"
export const LOAD_YEAR_MAKE_MODEL_TRIM = "LOAD_YEAR_MAKE_MODEL_TRIM"
export const GET_YEAR_MAKE_MODEL_TRIM = "GET_YEAR_MAKE_MODEL_TRIM";
export const FETCH_YEAR_MAKE_MODEL_TRIM = "FETCH_YEAR_MAKE_MODEL_TRIM"
export const SG_FETCH_YEAR_MAKE_MODEL_TRIM = "SG_FETCH_YEAR_MAKE_MODEL_TRIM"
export const SUBMIT_YEAR_MAKE_MODEL_TRIM = "SUBMIT_YEAR_MAKE_MODEL_TRIM"
export const SG_SUBMIT_YEAR_MAKE_MODEL_TRIM = "SG_SUBMIT_YEAR_MAKE_MODEL_TRIM"
//
export const GET_YEAR_MAKE_PENDING = "GET_YEAR_MAKE_PENDING"
export const LOAD_YEAR_MAKE = "LOAD_YEAR_MAKE"
export const GET_YEAR_MAKE = "GET_YEAR_MAKE";
export const FETCH_YEAR_MAKE = "FETCH_YEAR_MAKE"
export const SG_FETCH_YEAR_MAKE = "SG_FETCH_YEAR_MAKE"
export const SUBMIT_YEAR_MAKE = "SUBMIT_YEAR_MAKE"
export const SG_SUBMIT_YEAR_MAKE = "SG_SUBMIT_YEAR_MAKE"
//
export const LOAD_YEAR_SELECT = "LOAD_YEAR_SELECT"
export const GET_YEAR_SELECT = "GET_YEAR_SELECT";
export const FETCH_YEAR_SELECT = "FETCH_YEAR_SELECT"
export const SG_FETCH_YEAR_SELECT = "SG_FETCH_YEAR_SELECT"
//
// export const LOAD_KM_PRICE_SELECT = "LOAD_KM_PRICE_SELECT"
// export const GET_KM_PRICE_SELECT = "GET_KM_PRICE_SELECT";
// export const FETCH_KM_PRICE_SELECT = "FETCH_KM_PRICE_SELECT"
// export const SG_FETCH_KM_PRICE_SELECT = "SG_FETCH_KM_PRICE_SELECT"
//
export const LOAD_MAKE_SELECT = "LOAD_MAKE_SELECT"
export const GET_MAKE_SELECT = "GET_MAKE_SELECT";
export const FETCH_MAKE_SELECT = "FETCH_MAKE_SELECT"
export const SG_FETCH_MAKE_SELECT = "SG_FETCH_MAKE_SELECT"
//
export const LOAD_MODEL_SELECT = "LOAD_MODEL_SELECT"
export const GET_MODEL_SELECT = "GET_MODEL_SELECT";
export const FETCH_MODEL_SELECT = "FETCH_MODEL_SELECT"
export const SG_FETCH_MODEL_SELECT = "SG_FETCH_MODEL_SELECT"
//
export const LOAD_TRIM_SELECT = "LOAD_TRIM_SELECT"
export const GET_TRIM_SELECT = "GET_TRIM_SELECT";
export const FETCH_TRIM_SELECT = "FETCH_TRIM_SELECT"
export const SG_FETCH_TRIM_SELECT = "SG_FETCH_TRIM_SELECT"

//
export const LOAD_INVENTORY_SELECT_LIST = "LOAD_INVENTORY_SELECT_LIST"
export const GET_INVENTORY_SELECT_LIST = "GET_INVENTORY_SELECT_LIST";
export const FETCH_INVENTORY_SELECT_LIST = "FETCH_INVENTORY_SELECT_LIST"
export const SG_FETCH_INVENTORY_SELECT_LIST = "SG_FETCH_INVENTORY_SELECT_LIST"
//
export const ADD_WEBLEAD = "ADD_WEBLEAD"
export const CREATE_WEBLEAD = "CREATE_WEBLEAD"
//
export const ADD_TRADEIN = "ADD_TRADEIN"
export const CREATE_TRADEIN = "CREATE_TRADEIN"
//
export const ADD_LEASE_BUSTER = "ADD_LEASE_BUSTER"
export const CREATE_LEASE_BUSTER = "CREATE_LEASE_BUSTER"
//
export const ADD_APPOINTMENT = "ADD_APPOINTMENT"
export const CREATE_APPOINTMENT = "CREATE_APPOINTMENT"
//
export const UPDATE_FINANCE_APPLICATION = "UPDATE_FINANCE_APPLICATION"
export const SG_UPDATE_FINANCE_APPLICATION = "SG_UPDATE_FINANCE_APPLICATION"
export const ADD_FINANCE_APPLICATION = "ADD_FINANCE_APPLICATION"
export const CREATE_FINANCE_APPLICATION = "CREATE_FINANCE_APPLICATION"
//
export const ADD_CONTACT_US = "ADD_CONTACT_US";
export const CREATE_CONTACT_US = "CREATE_CONTACT_US";
export const CLEAR_CONTACT_US = 'CLEAR_CONTACT_US';
//
export const ADD_EMPLOYEE_TASK = "ADD_EMPLOYEE_TASK";
export const CREATE_EMPLOYEE_TASK = "CREATE_EMPLOYEE_TASK";
export const CLEAR_EMPLOYEE_TASK = 'CLEAR_EMPLOYEE_TASK';
//
export const LOAD_WEB_COMMENTS = "LOAD_WEB_COMMENTS";
export const GET_WEB_COMMENTS = "GET_WEB_COMMENTS";
export const ADD_WEB_COMMENT = "ADD_WEB_COMMENT";
export const CREATE_WEB_COMMENT = "CREATE_WEB_COMMENT";
export const CLEAR_WEB_COMMENT = 'CLEAR_WEB_COMMENT';
//
export const LOAD_PAGE_FILTERS = "LOAD_PAGE_FILTERS";
export const GET_PAGE_FILTERS = "GET_PAGE_FILTERS";
export const ADD_PAGE_FILTER = "ADD_PAGE_FILTER";
export const CREATE_PAGE_FILTER = "CREATE_PAGE_FILTER";
export const EDIT_PAGE_FILTER = "EDIT_PAGE_FILTER";
export const UPDATE_PAGE_FILTER = "UPDATE_PAGE_FILTER";
export const CLEAR_PAGE_FILTER = 'CLEAR_PAGE_FILTER';
export const RESET_PAGE_FILTER = 'RESET_PAGE_FILTER';
//
export const LOAD_BLACKBOOK_PENDING = "LOAD_BLACKBOOK_PENDING";
export const LOAD_BLACKBOOK_VALUES = "LOAD_BLACKBOOK_VALUES";
export const GET_BLACKBOOK_VALUES = "GET_BLACKBOOK_VALUES";
export const FETCH_BLACKBOOK_VALUES = "FETCH_BLACKBOOK_VALUES";
export const ONE_BLACKBOOK_VALUES = "ONE_BLACKBOOK_VALUES";
export const CLEAR_BLACKBOOK_VALUES ="CLEAR_BLACKBOOK_VALUES";
export const REMOVE_BLACKBOOK_VALUES = "REMOVE_BLACKBOOK_VALUES";
//
export const ADD_WEB_VISIT = "ADD_WEB_VISIT";
export const CREATE_WEB_VISIT = "CREATE_WEB_VISIT";
//