import { put, all, call, takeLatest } from 'redux-saga/effects'
import { 
    LOAD_BLACKBOOK_PENDING,
    LOAD_BLACKBOOK_VALUES,
    GET_BLACKBOOK_VALUES,
    FETCH_BLACKBOOK_VALUES,
    ONE_BLACKBOOK_VALUES,
    CLEAR_BLACKBOOK_VALUES,
    REMOVE_BLACKBOOK_VALUES,
    ALL_ERRORS } from "constants/actions";

import * as api from 'constants/api';


function* loadBlackbook(action) {
    yield put({ type: LOAD_BLACKBOOK_PENDING });
    
    try {
      const json = yield call(api.GET_BLACKBOOK_API, action.payload)
  
      yield put({ type: GET_BLACKBOOK_VALUES , payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* blackbookLoad() {
    yield takeLatest(LOAD_BLACKBOOK_VALUES, loadBlackbook);
  }

  function* fetchBlackbook(payload) {
    console.log(payload.payload)
    yield put({ type: FETCH_BLACKBOOK_VALUES, payload: payload.payload });
  }
  
  export function* blackbookFetch() {
    yield takeLatest(ONE_BLACKBOOK_VALUES, fetchBlackbook);
  }

  function* clearBlackbook() {
    yield put({ type: CLEAR_BLACKBOOK_VALUES });
  }
  
  export function* blackbookClear() {
    yield takeLatest(REMOVE_BLACKBOOK_VALUES, clearBlackbook);
  }


  export default function* index() {
    yield all([
      blackbookLoad(), 
      blackbookFetch(),
      blackbookClear(),
      ]);
  }
